import React, { useRef } from "react";
import SortArrowIcon from "../../layout/SortArrowIcon";
import { ColumnInstance } from "react-table";
import useClickOutsideOfElement from "../../../hooks/useClickOutsideOfElement";
import { motion } from "framer-motion";
import SupplyPortal from "../../misc/SupplyPortal";
import { getFontAwesomeIcon } from "../../../utils";
import { arrowContainerFadeInOutVariants } from "../../../motionVariants";
import { useSortArrowsViewModel } from "../../../hooks";

interface CustomColumnInstance<T extends object> extends ColumnInstance<T> {
	accessor: string | number | symbol | ((row: T) => any);
}

// Portal overlay component
const PortalOverlay: React.FC = () => (
	<SupplyPortal containerId="portal-root">
		<div className="bg-transparent w-full h-full absolute top-0"></div>
	</SupplyPortal>
);

interface SortArrowsProps<T extends object> {
	column: CustomColumnInstance<T>;
	setSortColumn: React.Dispatch<
		React.SetStateAction<{ [key: string]: string | string[] }>
	>;
	slug: string;
	isNested?: boolean;
	parentIndex?: number;
	onSortChange?: (columnId: string, direction: "asc" | "desc") => void;
}

const SortArrows = <T extends object>({
	column,
	setSortColumn,
	slug,
	isNested,
	parentIndex,
	onSortChange,
}: SortArrowsProps<T>): JSX.Element | null => {
	const containerRef = useRef<HTMLDivElement>(null);

	const {
		showArrowContainer,
		handleParentClick,
		handleResetSort,
		handleClick,
		isActive,
		activeDirection,
		sortOrderNumber,
		setShowArrowContainer,
	} = useSortArrowsViewModel({
		columnId: String(column.accessor),
		slug,
		isNested,
		parentIndex,
		onSortChange: onSortChange as
			| ((column: string, direction: "" | "asc" | "desc") => void)
			| undefined,
		setSortColumn,
	});

	useClickOutsideOfElement(containerRef, () => setShowArrowContainer(false));

	const arrowContainerClassNames = `${
		isActive ? "" : "hover:bg-white"
	} size-[18px] rounded-sm flex flex-col items-center justify-center cursor-pointer relative`;

	return (
		<div
			ref={containerRef}
			className={arrowContainerClassNames}
			onClick={handleParentClick}
		>
			{/* Active Arrow */}
			{isActive && (
				<div
					className={`text-lg text-supply-blue-400 ${
						activeDirection === "desc" ? "rotate-180" : ""
					} relative hover:text-supply-blue-300`}
				>
					{getFontAwesomeIcon("triangle", "solid")}
					<div
						className={`${
							activeDirection === "desc" ? "rotate-180" : ""
						} absolute text-white text-xs top-1.5 right-0 left-0`}
					>
						{sortOrderNumber}
					</div>
				</div>
			)}

			{/* Inactive Arrows */}
			{!isActive && (
				<>
					<SortArrowIcon
						slug={slug}
						parentIndex={parentIndex}
						columnName={String(column.render("Header"))}
						direction="up"
						setColumn={() => {}}
						isActive={false}
					/>
					<SortArrowIcon
						slug={slug}
						parentIndex={parentIndex}
						columnName={String(column.render("Header"))}
						direction="down"
						setColumn={() => {}}
						isActive={false}
					/>
				</>
			)}

			{/* Arrow Container Menu */}
			{showArrowContainer && (
				<motion.div
					className={`z-[9989] bg-white rounded flex flex-col border-1 absolute top-6 w-32 ${
						column.id === "col1" ? "left-[5px]" : ""
					}`}
					variants={arrowContainerFadeInOutVariants}
					initial="initial"
					animate="animate"
					exit="exit"
				>
					{/* Ascending Option */}
					{activeDirection !== "asc" && (
						<div
							className="flex border-b-1 p-1 hover:bg-blue-50"
							onClick={() => handleClick("up")}
						>
							<span className="pl-1 text-primary">
								{getFontAwesomeIcon("arrowUp", "regular")}
							</span>
							<span className="flex-1 text-left pl-4">
								Ascending
							</span>
						</div>
					)}
					{/* Descending Option */}
					{activeDirection !== "desc" && (
						<div
							className="flex p-1 hover:bg-blue-50 border-b-1"
							onClick={() => handleClick("down")}
						>
							<span className="pl-1 text-primary">
								{getFontAwesomeIcon("arrowDown", "regular")}
							</span>
							<span className="flex-1 text-left pl-4">
								Descending
							</span>
						</div>
					)}
					{/* Reset Option */}
					{isActive && (
						<div
							className="flex border-b-1 p-1 hover:bg-blue-50"
							onClick={handleResetSort}
						>
							<span className="pl-1 text-primary">
								{getFontAwesomeIcon("rotateLeft", "solid")}
							</span>
							<span className="flex-1 text-left pl-3">Reset</span>
						</div>
					)}
					{/* Overlay to prevent interaction with the rest of the page */}
					<PortalOverlay />
				</motion.div>
			)}
		</div>
	);
};

export default SortArrows;
