import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import { faWarehouseFull } from "@fortawesome/pro-light-svg-icons";
import useHostName from "../../hooks/useHostName";
import { faBox as faBoxSolid, faStore } from "@fortawesome/pro-solid-svg-icons";
import { faCalculator as faCalculatorSolid } from "@fortawesome/pro-solid-svg-icons";
import { useDarkMode } from "../../hooks";
import usePageListDetails from "../../pages/PageHooks/usePageListDetails";

interface Props {
	isMenuOpen: boolean;
	handleToggleMenu: () => void;
}

const SlideMenu: React.FC<Props> = ({ isMenuOpen, handleToggleMenu }) => {
	const { darkMode } = useDarkMode();
	const [isVisible, setIsVisible] = useState(false);
	const hostName = useHostName();
	const { pageMeta } = usePageListDetails("navigation");
	const hasOrdersPagePermissions =
		pageMeta?.acl?.["sales-orders"]?.["navigation-sales-orders"] ?? false;
	const hasItemsPagePermissions =
		pageMeta?.acl?.["items"]?.["navigation-items"] ?? false;
	const hasVendorItemsPagePermissions =
		pageMeta?.acl?.["vendor-items"]?.["navigation-vendor-items"] ?? false;
	const isDevelopment = process.env.NODE_ENV === "development";
	let menuItems: Record<string, any> = [];

	const decideMenuOptions = () => {
		if (
			hasOrdersPagePermissions &&
			hasItemsPagePermissions &&
			hasVendorItemsPagePermissions
		) {
			return [
				{ name: "Orders", link: "/", icon: faCalculatorSolid },
				{ name: "Items", link: "/items", icon: faBoxSolid },
				{
					name: "Vendor Items",
					link: "/vendor-items",
					icon: faStore,
				},
			];
		}
		if (
			hasOrdersPagePermissions &&
			hasItemsPagePermissions &&
			!hasVendorItemsPagePermissions
		) {
			return [
				{ name: "Orders", link: "/", icon: faCalculatorSolid },
				{ name: "Items", link: "/items", icon: faBoxSolid },
			];
		}
		if (
			hasOrdersPagePermissions &&
			!hasItemsPagePermissions &&
			hasVendorItemsPagePermissions
		) {
			return [
				{ name: "Orders", link: "/", icon: faCalculatorSolid },
				{ name: "Items", link: "/items", icon: faBoxSolid },
			];
		}
		if (
			hasOrdersPagePermissions &&
			!hasItemsPagePermissions &&
			!hasVendorItemsPagePermissions
		) {
			return [{ name: "Orders", link: "/", icon: faCalculatorSolid }];
		}
		return [];
	};
	if (hostName.hostName === "BANKOFAMERICA") {
		menuItems = [
			{
				name: "Inventory",
				link: "/",
				icon: faWarehouseFull,
			},
		];
	} else if (hostName.hostName === "COMPASS") {
		menuItems = decideMenuOptions();
	} else {
		menuItems = [
			{ name: "Orders", link: "/", icon: faCalculatorSolid },
			// { name: "Inventory", link: "/Inventory", icon: faWarehouseFull },
			...(isDevelopment
				? [
						{
							name: "Items",
							link: "/items",
							icon: faWarehouseFull,
						},
						{
							name: "Shipments",
							link: "/shipments",
							icon: faWarehouseFull,
						},
				  ]
				: []),
		];
	}

	useEffect(() => {
		setIsVisible(isMenuOpen);
	}, [isMenuOpen]);

	const handleLinkClick = () => {
		localStorage.removeItem("activeSearchColumn");
		localStorage.removeItem("activeHeader"); // Remove the activeHeader from local storage
		localStorage.removeItem("approvalFilter");
		localStorage.removeItem("activeSortColumns");
		handleToggleMenu();
	};

	return (
		menuItems && (
			<div
				role={"menu"}
				className={`glass slide-menu text-navy-500 ${
					darkMode ? "bg-primary" : "bg-white"
				} h-full py-14 px-12 text-left transform ${
					isVisible ? "opacity-100" : "opacity-0"
				} transition-opacity duration-300 ${
					isMenuOpen ? "block" : "hidden"
				}`}
			>
				<h4 className="pb-4 font-bold text-xl">TOGa Supply</h4>
				<nav>
					<ul className="pl-2">
						{menuItems.map((item: Record<string, any>) => (
							<li key={item.name}>
								<Link
									className={`p-2 flex items-center ${
										darkMode
											? "hover:bg-accent"
											: "hover:bg-primaryVeryLight"
									}  rounded-md`}
									to={item.link}
									onClick={handleLinkClick}
								>
									<FontAwesomeIcon
										icon={item.icon as IconProp}
										className="mr-4"
									/>
									{item.name}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</div>
		)
	);
};

export default SlideMenu;
