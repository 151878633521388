import React, { useEffect, useState, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { getEventLogs } from "../../../api/toga";
import BaseButton from "../BaseButton";
import LoadingMessage from "../../misc/LoadingMessage";
import formatTimestamp from "../../../utils/formatDateForEventLogs";
import formatEvents from "./helperFunctions/formatEvents";
import formatNotes from "./helperFunctions/formatNotes";

interface LessButtonProps {
	onClick: () => void;
}

export interface TimelineItemProps {
	id: number;
	itemTitle: string;
	itemTerm: string;
	itemValue: string;
	itemTime: string;
	itemUser: string;
	longDescription?: string;
	additionalUpdates?: string[];
}

const EventLog: React.FC<{
	title: string;
	isOpen: boolean;
	parentData: any;
	slug: string;
	hasActivityToggle?: boolean;
}> = ({ title, isOpen, parentData, slug, hasActivityToggle }) => {
	const textClassNames = "px-1 text-sm";
	let [eventLogsData, setEventLogsData] = useState<Record<string, any>[]>();
	let [activityNotesData, setActivityNotesData] =
		useState<Record<string, any>[]>();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showEventLog, setShowEventLog] = useState<boolean>(false);

	const boldClassNames = "font-omnes-medium text-gray-800";
	const accentedTextClassNames = "text-gray-600";
	const timeLineContainerClassNames = "flex flex-col py-3 ml-4 text-left";
	const parentContainerClassNames =
		"relative pt-2 mx-8 sticky overflow-scroll max-h-[90vh] top-0";
	const liClassNames = "relative flex items-center pl-2 ";
	const renderLineBetweenItems = (
		index: number,
		totalCount?: number
	): JSX.Element | null => {
		if (totalCount && totalCount <= 1) {
			return null;
		} else {
			const baseStyles = "absolute w-0.5 bg-navy-200 left-1 z-1";
			const positionStyle = index === 0 ? "top-[18px]" : "";
			const heightStyle = "h-[100%]";
			const combinedStyles = `${baseStyles} ${positionStyle} ${heightStyle}`;
			return <div className={combinedStyles} />;
		}
	};

	const getCircleClassNames = (index: number): string => {
		return `absolute left-[1px] top-[18px] size-2 rounded-full z-10 ${
			index === 0 ? "bg-black" : "bg-navy-200"
		}`;
	};

	// State to control the visibility of longDescription and additionalUpdates for each item
	const [visibleItems, setVisibleItems] = useState<{
		[key: number]: boolean;
	}>({});

	const toggleVisibility = (itemId: number) => {
		setVisibleItems((prev) => ({
			...prev,
			[itemId]: !prev[itemId],
		}));
	};

	const LessButton: React.FC<LessButtonProps> = ({ onClick }) => {
		return (
			<div
				onClick={onClick}
				className="text-sm text-primary hover:cursor-pointer"
			>
				Less
			</div>
		);
	};

	const handleActivityClick = () => {
		setShowEventLog(false);
	};

	const handleEventLogClick = () => {
		setShowEventLog(true);
	};

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const eventLogApiData = await getEventLogs(
					slug,
					parentData.uuid
				);

				// filters out data that has a note -> this is approval/deny notes
				const activityNotes = eventLogApiData.filter(
					(item: Record<string, any>) => {
						return item.note !== null;
					}
				);

				// filters out data that does not have a note and also contains record logs -> this is event log data
				const eventLogs = eventLogApiData.filter(
					(item: Record<string, any>) =>
						item.note === null &&
						Array.isArray(item.recordFieldLogs) &&
						item.recordFieldLogs.length !== 0
				);

				const formattedNotes = formatNotes(
					activityNotes,
					boldClassNames
				);

				const formattedEventLogs = formatEvents(
					eventLogs,
					boldClassNames
				);

				if (!hasActivityToggle) {
					setShowEventLog(true);
				}
				setActivityNotesData(formattedNotes);
				setEventLogsData(formattedEventLogs);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [isOpen]);

	let activeBorderClasses =
		"border-t-transparent border-b-2 border-navy-500 text-navy-500";
	let inactiveBorderClasses =
		"border-t-transparent border-b-2 border-gray-400 text-gray-400 hover:text-primary hover:border-b-primary";

	return (
		<div className={parentContainerClassNames}>
			<div className="font-omnes-medium sticky top-[-15px] pt-8 pb-6 z-20 bg-white text-left flex flex-col justify-end text-lg">
				{title}
			</div>
			{isLoading ? (
				<div className="flex items-center justify-center min-h-96">
					<LoadingMessage message={""} />
				</div>
			) : eventLogsData?.length === 0 &&
			  activityNotesData?.length === 0 ? (
				<div className="flex w-full items-start pt-1">
					<p className="text-sm text-navy-500 font-omnes-regular">
						No Events or Activities Logged
					</p>
				</div>
			) : (
				<div>
					{hasActivityToggle && (
						<div className="flex">
							<BaseButton
								text="Activity"
								color="white"
								fontColor=""
								borderColor=""
								hoverFontColor="navy-500"
								additionalClasses={`border-x-transparent p-2 ${
									!showEventLog
										? activeBorderClasses
										: inactiveBorderClasses
								}`}
								ariaSelected={!showEventLog}
								size="sm"
								shape=""
								as="button"
								iconSize="text-lg pr-0 size-3"
								onClick={handleActivityClick}
							/>
							<BaseButton
								text="Events"
								color="white"
								fontColor=""
								borderColor=""
								hoverFontColor="navy-500"
								additionalClasses={`border-x-transparent p-2 ${
									showEventLog
										? activeBorderClasses
										: inactiveBorderClasses
								}`}
								size="sm"
								shape=""
								as="button"
								iconSize="text-lg pr-0 size-3"
								onClick={handleEventLogClick}
								ariaSelected={showEventLog}
							/>
						</div>
					)}
					{showEventLog &&
						(eventLogsData && eventLogsData.length === 0 ? (
							<div className="flex">
								<p className="ml-4 py-3 text-sm">No Events</p>
							</div>
						) : (
							<ul className="relative">
								{eventLogsData?.map(
									(
										item: Record<string, any>,
										index: number
									) => {
										const circleClassNames =
											getCircleClassNames(index);
										return (
											<li
												key={item.id}
												className={liClassNames}
											>
												{renderLineBetweenItems(
													index,
													eventLogsData?.length
												)}

												<div
													className={
														timeLineContainerClassNames
													}
												>
													<div
														className={
															circleClassNames
														}
													/>
													{!item.itemNote ? (
														<span
															className={`${textClassNames} ${accentedTextClassNames}`}
														>
															{item.itemTitle}
														</span>
													) : (
														<span
															className={`${textClassNames} ${accentedTextClassNames}`}
														>
															{item.itemNote}
														</span>
													)}
													{item.longDescription && (
														<button
															className="text-sm text-primary"
															onClick={() =>
																toggleVisibility(
																	item.id
																)
															}
														>
															Long Description
														</button>
													)}
													{item.additionalUpdates && (
														<button
															className="text-sm text-primary"
															onClick={() =>
																toggleVisibility(
																	item.id
																)
															}
														>
															More Updates
														</button>
													)}
													<div className="flex">
														<span
															className={
																textClassNames
															}
														>
															{item.itemUser}
														</span>
														<span
															className={`${textClassNames} ${accentedTextClassNames}`}
														>
															{item.itemTime}
														</span>
													</div>
												</div>
											</li>
										);
									}
								)}
							</ul>
						))}
					{hasActivityToggle &&
						!showEventLog &&
						(hasActivityToggle &&
						activityNotesData &&
						activityNotesData.length === 0 ? (
							<div className="flex">
								<p className="ml-4 py-3 text-sm">
									No Activities
								</p>
							</div>
						) : (
							<ul className="relative">
								{activityNotesData?.map(
									(
										item: Record<string, any>,
										index: number
									) => {
										const circleClassNames =
											getCircleClassNames(index);
										return (
											<li
												key={item.id}
												className={liClassNames}
											>
												{renderLineBetweenItems(
													index,
													activityNotesData?.length
												)}

												<div
													className={
														timeLineContainerClassNames
													}
												>
													<div
														className={
															circleClassNames
														}
													/>
													<span
														className={`${textClassNames} ${accentedTextClassNames}`}
													>
														{item.itemTitle}
													</span>
													<div className="flex">
														<span
															className={`${textClassNames} ${accentedTextClassNames}`}
														>
															{item.itemTime}
														</span>
													</div>
												</div>
											</li>
										);
									}
								)}
							</ul>
						))}
				</div>
			)}
		</div>
	);
};

export default EventLog;
