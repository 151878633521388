import React, { useRef, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ApprovalViewModalContent from "./ApprovalsViewModalContent";
import LoadingMessage from "../../../../../components/misc/LoadingMessage";
import {
	slideUpModalVariants,
	slideUpModalOverlayVariants,
	tableVariants,
} from "../../../../../motionVariants";
import { useItemsContext } from "../../../../../hooks/dataTableHooks/useItemsContext";
import { clearLocalStorage } from "../../../../../utils";

type ApprovalViewSliderModalProps = {
	isOpen: boolean;
	onClose: () => void;
	hasBorder?: boolean;
	borderColor?: string;
	loadingMessage?: string;
	navChildren?: React.ReactNode;
	cancelButton?: React.ReactNode;
	saveButton?: React.ReactNode;
	closeButton?: (handleClose: () => void) => React.ReactNode;
	activeUuid?: string | null | undefined;
	updateRowData?: (uuid: string, key: string, newStatus: string) => void;
	setActiveRowUuid?: any;
};

const ApprovalViewSliderModal: React.FC<ApprovalViewSliderModalProps> = ({
	isOpen,
	onClose,
	hasBorder = false,
	borderColor = "primary",
	loadingMessage = "",
	navChildren,
	cancelButton,
	saveButton,
	closeButton,
	updateRowData,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const { activeUuid, setActiveRowUuid } = useItemsContext();

	const handleClose = () => {
		setIsMenuOpen(false);
		onClose();
		clearLocalStorage(["uuid", "orders"]);
		document.body.classList.remove("overflow-hidden");
	};

	// we needed to update state (setActiveRowUuid) in response to a prop change (isOpen).
	// Attempting to update state directly during the render phase causes React to throw a
	// warning because it can lead to inconsistent UI and unpredictable behavior
	// this needs to be done in order for the real time update in the data table to work
	// from the Approvals modal
	// useEffect handles side effects related to opening and closing the modal
	useEffect(() => {
		if (isOpen) {
			// Side effect: Update state based on prop change
			setActiveRowUuid(activeUuid);
			// Side effect: Modify DOM by adding a class
			document.body.classList.add("overflow-hidden");
		} else {
			// Clean up when modal is closed
			document.body.classList.remove("overflow-hidden");
		}
		// Clean up when component unmounts
		return () => {
			document.body.classList.remove("overflow-hidden");
		};
		// Dependencies ensure effect runs when these values change
	}, [isOpen, activeUuid, setActiveRowUuid]);

	const modalContainerClassNames =
		"fixed inset-0 flex items-end justify-center z-50 max-w-[1690px] mx-auto modal-bottom";
	const modalBorderClassNames = `modal-box min-w-wideModal border-0 h-[95dvh] p-0 ${
		isMenuOpen ? "overflow-hidden pt-0" : "pt-0"
	} ${hasBorder ? `border-${borderColor}` : "border-transparent"}`;
	const modalOverLayClassNames =
		"fixed inset-0 bg-gray-300 z-40 hover:cursor-pointer";

	return (
		<AnimatePresence>
			{isOpen && (
				<>
					<motion.div
						className={modalContainerClassNames}
						onClick={handleClose}
						initial={slideUpModalVariants.initial()}
						animate={slideUpModalVariants.animate()}
						exit={slideUpModalVariants.exit()}
						transition={slideUpModalVariants.transition}
					>
						<div
							className="min-w-full"
							onClick={(e) => e.stopPropagation()}
						>
							{isLoading ? (
								<motion.div
									{...tableVariants}
									className={`${modalBorderClassNames} min-full min-h-[95vh] flex justify-center items-center`}
								>
									<div className="h-full">
										<LoadingMessage
											message={loadingMessage}
										/>
									</div>
								</motion.div>
							) : (
								<div className={modalBorderClassNames}>
									<motion.div>
										{navChildren && (
											<div className="nav-children">
												{navChildren}
											</div>
										)}
										<ApprovalViewModalContent
											modalRef={modalRef}
											handleClose={handleClose}
											activeUuid={activeUuid}
											updateRowData={updateRowData}
											setActiveRowUuid={setActiveRowUuid}
										/>
										{(cancelButton ||
											saveButton ||
											closeButton) && (
											<div className="flex justify-between p-4">
												{cancelButton}
												{saveButton}
												{closeButton &&
													closeButton(handleClose)}
											</div>
										)}
									</motion.div>
								</div>
							)}
						</div>
					</motion.div>

					<motion.div
						{...slideUpModalOverlayVariants}
						className={modalOverLayClassNames}
						onClick={handleClose}
					></motion.div>
				</>
			)}
		</AnimatePresence>
	);
};

export default ApprovalViewSliderModal;
