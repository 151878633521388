// GENERAL
import React, { useEffect, useState } from "react";
import { getFontAwesomeIcon } from "../../../../../utils";
import { AnimatePresence, motion } from "framer-motion";

// HOOKS
import {
	getSalesOrderItems,
	getSalesOrderDetails,
} from "../../../listing/api/OrdersApi";
import {
	slideOutMenuOverlayVariant,
	slideOutMenuVariants,
} from "../../../../../motionVariants";
import useHostName from "../../../../../hooks/useHostName";
// COMPONENTS
import BaseText from "../../../../../components/ui/BaseText";
import BasePill from "../../../../../components/ui/BasePill";
import BaseButton from "../../../../../components/ui/BaseButton";
import { StatusColorDot } from "../../../../../components/ui/Tables";
import ApprovalContentHeader from "./ApprovalContentHeader";
import ApprovalContentSection from "./ApprovalContentSection";
import BaseContainer from "../../../../../components/ui/BaseContainer";
import ApprovalContentItems from "./ApprovalContentItems";
import QuickActionModal from "../../../listing/modals/QuickActionModal";
import BaseToolTip from "../../../../../components/ui/BaseToolTip";
import { LoadingMessage, SupplyPortal } from "../../../../../components/misc";
import { EventLog } from "../../../../../components/ui";
import AddNotesForm from "../../../../../components/ui/AddNotes/AddNotesForm";
import BaseModal from "../../../../../components/layout/Modal/BaseModal";
// UTILS
import formatCurrency from "../../../../../utils/formatCurrency";
import formatDate from "../../../../../utils/formatDateForDetailsBox";
import {
	useItemsContext,
	usePrivilegeCheck,
	useDataTableContext,
} from "../../../../../hooks";
import checkLeaseTotals from "../utils/checkLeaseTotals";
// TYPES
import { ApprovalDetails, ApprovalViewProps, ModalType } from "./types";
//DUMMY FIELDS
import { dummyFields } from "./APPROVALDETAILSROWFIELDS";

const modalVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1 },
	exit: { opacity: 0 },
};

interface ApprovalContentHeaderField {
	id: string;
	label: string;
	valueKey?: string;
}

interface ApprovalContentField {
	id: string;
	label: string;
	tooltip?: string;
	icon?: string;
	iconStyle?: string;
}

interface DynamicItemsField {
	id: string;
	label: string;
}

type ApprovalDetailRows = [
	{
		APPROVALCONTENTHEADERFIELDS: ApprovalContentHeaderField[];
	},
	{
		APPROVALCONTENTFIELDS: ApprovalContentField[];
	},
	{
		DYNAMICITEMSFIELDS: DynamicItemsField[];
	}
];

interface DummyFields {
	APPROVALDETAILROWS: ApprovalDetailRows;
}

// FIGMA DOCUMENTATION (lease pricing fields + displaying bundles+bundle items): https://www.figma.com/design/wtVOcbRCEL5r3Kj0UgrB4C/Supply?node-id=122-12

const ApprovalViewModalContent: React.FC<ApprovalViewProps> = ({
	modalRef,
	borderColor = "primary",
	hasBorder = false,
	handleClose,
	activeUuid,
	updateRowData,
}) => {
	const { isAdmin } = usePrivilegeCheck();
	const { setDeactivatedQuickMenuIds } = useItemsContext();

	const { pageMeta } = useDataTableContext();
	const hostName = useHostName().hostName;

	const modalBorderClassNames = `bg-white min-w-wideModal rounded-3xl min-h-[95dvh] ${
		hasBorder ? `border-${borderColor}` : "border-transparent"
	}`;
	const slideOutMenuClassNames = `slide-out-menu bg-white w-1/2 min-w-[300px] max-w-[500px] absolute top-0 left-0 z-40 z-[9999]`;
	const overlayClassNames = `bg-black absolute z-1 w-full left-0 top-0 cursor-pointer z-[9989]`;
	const overlayHeight = "100%";
	const menuItemClassNames = "sticky min-h-full max-h-full mb-20";

	const eventLogTitle = "View Log";
	const [
		hasNavigationCompassEditPermission,
		setHasNavigationCompassEditPermission,
	] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [refetchData, setRefetchData] = useState<boolean>(false);
	const [activeModal, setActiveModal] = useState<ModalType | null>(null);
	const [orderAddressDetails, setOrderAddressDetails] = useState<
		ApprovalDetails | null | undefined
	>();
	const [orderDetails, setOrderDetails] = useState<
		ApprovalDetails | null | undefined
	>();
	const [items, setItemsDetails] = useState<any | null | undefined>();
	const [leasePrices, setLeasePrices] = useState<any | null | undefined>();
	const handleButtonClick = (modalType: ModalType) => {
		setActiveModal(modalType);
	};
	const [isEventLogOpen, setIsEventLogOpen] = useState<boolean>(false);
	const [headerFields, setHeaderFields] = useState<Record<string, any>[]>([]);
	const [contentFields, setContentFields] = useState<Record<string, any>[]>(
		[]
	);
	const [dynamicContentFields, setDynamicContentFields] = useState<
		Record<string, any>[]
	>([]);
	const [isViewAllNotesOpen, setIsViewAllNotesOpen] =
		useState<boolean>(false);

	const toggleMenu = () => {
		setIsEventLogOpen(!isEventLogOpen);
	};

	const toggleViewAllNotes = () => {
		setIsViewAllNotesOpen(!isViewAllNotesOpen);
	};

	const handleRefetchData = () => {
		console.log("handleRefetchData called");
		setRefetchData((prevState) => !prevState);
	};

	useEffect(() => {
		const fetchOrderBoxDetails = async () => {
			const id = activeUuid;
			if (id && pageMeta) {
				setIsLoading(true);
				const hasPermissions =
					pageMeta?.acl["sales-orders"][
						"navigation-edit-on-commerce"
					];
				const salesOrderItems = await getSalesOrderItems(id);

				const salesOrderDetails = await getSalesOrderDetails(id);

				if (salesOrderItems) {
					const updatedItems = checkLeaseTotals(
						salesOrderItems,
						salesOrderDetails
					);
					if (updatedItems) {
						setLeasePrices(updatedItems);
					}
				}
				setHasNavigationCompassEditPermission(hasPermissions);
				setHeaderFields(
					dummyFields.APPROVALDETAILROWS[0]
						.APPROVALCONTENTHEADERFIELDS
				);

				setContentFields(
					dummyFields.APPROVALDETAILROWS[1].APPROVALCONTENTFIELDS
				);
				setDynamicContentFields([
					dummyFields.APPROVALDETAILROWS[2].DYNAMICITEMSFIELDS,
				]);
				setOrderDetails(salesOrderDetails);
				setOrderAddressDetails(salesOrderDetails?.shipToAddress);
				setItemsDetails(salesOrderItems);
				setIsLoading(false);
			}
		};

		fetchOrderBoxDetails();
	}, [activeUuid, refetchData, pageMeta]);

	const salesOrderNotes =
		orderDetails?.salesOrderNotes?.slice().reverse() || [];
	const adminNotes = salesOrderNotes?.filter(
		(note: any) => note?.salesOrderNoteType.slug === "admin"
	);
	const reasonForRequest = salesOrderNotes
		?.filter(
			(note: any) => note?.salesOrderNoteType.name === "Request Reason"
		)
		.map((note: { note: string }) => note.note);

	const shippingInstructions = salesOrderNotes
		?.filter(
			(note: any) =>
				note?.salesOrderNoteType.name === "Shipping Instructions"
		)
		.map((note: { note: string }) => note.note);

	const baseSalesOrderPathPath = orderDetails;
	const dateOrder = formatDate(orderDetails?.dateOrder);
	const subTotal = formatCurrency(
		baseSalesOrderPathPath?._subtotalPurchase || "0"
	);
	const tax = formatCurrency(Number(baseSalesOrderPathPath?.tax) || "0");
	const total = formatCurrency(baseSalesOrderPathPath?._totalPurchase || "0");
	const orderTotalLease = formatCurrency(
		baseSalesOrderPathPath?._totalLease || "0"
	);
	const shipping = formatCurrency(baseSalesOrderPathPath?.shipping || "0");
	const orderNumber = orderDetails?.number || "—";
	const orderStatus = orderDetails?._status || "—";
	const shipAddresse = orderAddressDetails?.addressee;
	const shipAttention = orderAddressDetails?.attention;
	const shipLine1 = orderAddressDetails?.line1;
	const shipLine2 = orderAddressDetails?.line2;
	const shipCity = orderAddressDetails?.city;
	const shipCode = orderAddressDetails?.state?.code;
	const shipZip = orderAddressDetails?.zip;
	const shipPhone = orderAddressDetails?.phoneNumber;

	const shippingMethod = orderDetails?.shippingMethod?.name;
	const shippingPriority = orderDetails?.urgency?.name;

	const costCenter = orderDetails?.c_erpEntityId || "—";

	const notificationEmail =
		orderDetails?.salesOrderEmailAddresses.map(
			(email: { emailAddress: string }) => email.emailAddress ?? "—"
		) ?? [];
	const customerName =
		orderDetails?.contact?.firstName +
		" " +
		orderDetails?.contact?.lastName;
	const submittedByUsers = orderDetails?.createdByUser?._name || "";
	const assignedManager = orderDetails?.approvalUser?._name || "";

	const showActionLinks = [
		"pendingApproval",
		"pendingInitialApproval",
	].includes(orderStatus);

	let skipManagerApproval = false;
	//If Total is > 5000 hide the skip manager approval button
	if (total && Number(total) > 5000) {
		skipManagerApproval = true;
	}

	return (
		pageMeta && (
			<div>
				{isLoading ? (
					<div className="flex items-center justify-center h-svh ">
						<LoadingMessage message={""} />
					</div>
				) : !orderAddressDetails || !orderDetails || !items ? (
					<div className="flex items-center justify-center h-svh">
						An error took place.
					</div>
				) : (
					<div ref={modalRef} className={modalBorderClassNames}>
						<div className="py-8 px-2 lg:px-10">
							<section className="flex flex-col">
								<div className="pb-8 ">
									<motion.header
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.2 }}
										className="flex flex-col items-between justify-between sticky top-[-5px] bg-white z-20 max-lg:flex-col min-h-[62px]"
										aria-label="approval details modal header."
									>
										<div className="flex flex-col items-start lg:flex-row lg:items-center justify-between">
											<div
												className={
													"hidden max-lg:flex w-full justify-end py-2"
												}
											>
												{
													<>
														{(hostName ===
															"COMPASS" &&
															isAdmin) ||
														hostName !==
															"COMPASS" ? (
															<BaseButton
																text="View Log"
																color="white"
																hoverBackground="primaryHoverLight"
																fontColor="primary"
																borderColor="primary"
																additionalClasses={`text-nowrap py-1 px-3 mx-5px max-sm:size-8 max-sm:justify-center text-14px`}
																shape="rounded"
																iconPadding="pr-2 max-sm:pr-0"
																icon={getFontAwesomeIcon(
																	"clock",
																	"regular"
																)}
																iconBreakpoint="max-lg:pr-0"
																size="sm"
																as="button"
																hasTextBreakpoint={
																	true
																}
																textBreakpoint="max-lg:hidden"
																onClick={
																	toggleMenu
																}
																fontFamily="omnes-medium"
															/>
														) : null}
														<BaseToolTip
															message="Open in New Tab"
															toolTipPlacement="top"
															children={
																<BaseButton
																	text=""
																	color="white"
																	fontColor="navy-300"
																	borderColor="none"
																	hoverFontColor="navy-500"
																	additionalClasses={`p-2 mx-4px`}
																	size="sm"
																	shape="rounded"
																	iconLabel="Close"
																	icon={getFontAwesomeIcon(
																		"arrowUpRightFromSquare",
																		"regular"
																	)}
																	as="button"
																	onClick={() =>
																		window.open(
																			window
																				.location
																				.href,
																			"_blank"
																		)
																	}
																	iconSize="text-lg pr-0 size-3"
																/>
															}
														/>
														<BaseToolTip
															message="Close"
															children={
																<BaseButton
																	text=""
																	color="white"
																	fontColor="navy-300"
																	borderColor="none"
																	hoverFontColor="navy-500"
																	additionalClasses={`p-2 mx-4px`}
																	size="sm"
																	shape="rounded"
																	iconLabel="Close"
																	icon={getFontAwesomeIcon(
																		"Xmark",
																		"regular"
																	)}
																	as="button"
																	onClick={
																		handleClose
																	}
																	iconSize="text-lg pr-0 size-3"
																/>
															}
														/>
													</>
												}
											</div>
											<div className="flex items-center w-full max-sm:flex-col max-sm:items-start">
												<BasePill
													text={
														<BaseText
															size={"text-sm"}
															color={
																"text-navy-500"
															}
															text={"Order"}
															fontFamily={
																"font-omnes-medium"
															}
															tag={"h1"}
														/>
													}
													firstIcon={getFontAwesomeIcon(
														"calculator",
														"solid"
													)}
													hoverBackgroundColor="none"
													additionalClasses="px-2.5 py-1 h-26px mr-1.5"
													shape="rounded-full"
													color="bg-supply-blue-50"
													borderColor="transparent"
													type={"span"}
													hasMobileStyle={false}
												/>
												<div className="flex items-center max-lg:py-2">
													<div className="flex items-center font-omnes-regular gap-1 max-sm:flex-col">
														<h2 className="flex items-center text-sm mt-0">
															<span className="text-navy-350 pr-.5 text-nowrap">
																Order Date:
															</span>
															<span className="text-navy-500 pl-1 mr-3 text-nowrap">
																{dateOrder}
															</span>
														</h2>
														<div className="flex flex-row gap-2 max-lg:w-full">
															<StatusColorDot
																status={
																	orderStatus
																}
																additionalClasses="flex items-center text-nowrap"
																useConvertedStatus={
																	true
																}
																fontFamily="font-omnes-regular text-sm"
															/>
														</div>
													</div>
												</div>
											</div>

											<div className="flex items-center lg:my-0 my-2  max-lg:px-2 max-lg:w-full max-lg:justify-between">
												{showActionLinks && (
													<>
														<div className="flex gap-2 mr-3 ">
															<BaseToolTip
																message={
																	assignedManager
																		? "Approve"
																		: "Needs an Assigned Manager"
																}
																toolTipPlacement="top"
															>
																<BaseButton
																	borderColor="gray-100"
																	icon={getFontAwesomeIcon(
																		"check",
																		"solid"
																	)}
																	iconSize="text-base"
																	hoverBackground="mint-200"
																	hoverBorderColor="mint-700"
																	additionalClasses={`p-1 h-8 w-8 text-mint-700  `}
																	shape="semiRounded"
																	onClick={() =>
																		handleButtonClick(
																			"approve"
																		)
																	}
																	isDisabled={
																		!assignedManager
																	}
																	disabledClasses={
																		"text-gray-200 hover:cursor-not-allowed hover:bg-transparent rounded-md"
																	}
																/>
															</BaseToolTip>
															<BaseToolTip
																message="Deny"
																toolTipPlacement="top"
															>
																<BaseButton
																	borderColor="gray-100"
																	icon={getFontAwesomeIcon(
																		"xmark",
																		"solid"
																	)}
																	iconSize="text-base"
																	additionalClasses="p-1 h-8 w-8 text-crimson-700 hover:bg-crimson-300 hover:border-crimson-700"
																	shape="semiRounded"
																	onClick={() =>
																		handleButtonClick(
																			"deny"
																		)
																	}
																/>
															</BaseToolTip>
															<BaseToolTip
																message="Assign & Manage"
																toolTipPlacement="top"
															>
																<BaseButton
																	borderColor="gray-100"
																	icon={getFontAwesomeIcon(
																		"userCheck",
																		"solid"
																	)}
																	iconSize="text-base"
																	additionalClasses="p-1 h-8 w-8 text-primary hover:bg-primaryHoverLight hover:border-primary"
																	shape="semiRounded"
																	onClick={() =>
																		handleButtonClick(
																			"assign"
																		)
																	}
																/>
															</BaseToolTip>
															{isAdmin &&
																!skipManagerApproval && (
																	<BaseToolTip
																		message="Manager Override"
																		toolTipPlacement="top"
																	>
																		<BaseButton
																			borderColor="gray-100"
																			icon={getFontAwesomeIcon(
																				"badgeCheck",
																				"solid"
																			)}
																			iconSize="text-base"
																			additionalClasses="p-1 h-8 w-8 text-mint-700 hover:bg-mint-200 hover:border-mint-700"
																			shape="semiRounded"
																			onClick={() =>
																				handleButtonClick(
																					"adminOnly"
																				)
																			}
																		/>
																	</BaseToolTip>
																)}
														</div>
														<div className="border-l border-gray-300 h-8 max-lg:hidden"></div>
													</>
												)}

												<div className="flex items-center flex-row ml-2 gap-2 max-lg:hidden">
													{(hostName === "COMPASS" &&
														isAdmin) ||
													hostName !== "COMPASS" ? (
														<BaseButton
															text="View Log"
															color="white"
															hoverBackground="primaryHoverLight"
															fontColor="primary"
															borderColor="primary"
															additionalClasses={`text-nowrap py-1 px-3 mx-5px  text-14px`}
															shape="rounded"
															iconPadding="pr-2"
															icon={getFontAwesomeIcon(
																"clock",
																"regular"
															)}
															size="sm"
															as="button"
															hasTextBreakpoint={
																true
															}
															onClick={toggleMenu}
															fontFamily="omnes-medium"
														/>
													) : null}
													<BaseToolTip
														message="Open in New Tab"
														toolTipPlacement="top"
													>
														<BaseButton
															borderColor="none"
															icon={getFontAwesomeIcon(
																"ArrowUpRightFromSquare",
																"regular"
															)}
															onClick={() =>
																window.open(
																	window
																		.location
																		.href,
																	"_blank"
																)
															}
															as="button"
															iconSize="text-lg"
															additionalClasses="h-8 w-8 text-gray-400 hover:text-gray-700"
														/>
													</BaseToolTip>
													<BaseToolTip
														message="Close"
														toolTipPlacement="top"
													>
														<BaseButton
															borderColor="none"
															iconLabel="Close"
															icon={getFontAwesomeIcon(
																"xmark",
																"regular"
															)}
															as="button"
															onClick={
																handleClose
															}
															iconSize="text-lg"
															additionalClasses="-mr-2 h-8 w-8 text-gray-400 hover:text-gray-700"
														/>
													</BaseToolTip>
												</div>
											</div>
										</div>
										<div className="flex items-start max-lg:py-2">
											<BaseText
												size={"text-md"}
												color={"text-navy-500"}
												text={`Order ${orderNumber}`}
												fontFamily={"font-omnes-medium"}
												tag={"h1"}
											/>
										</div>
									</motion.header>
								</div>
								{isAdmin && activeUuid && (
									<BaseContainer
										columnContainerClasses="flex flex-col items-start w-full bg-gray-100 py-2.5 px-3 rounded-xl border border-gray-200 mb-5"
										columnShape="semi-rounded"
										borderColor="navy-200"
										isEnabled={true}
									>
										<AddNotesForm
											notesData={adminNotes}
											openViewAllNotes={
												toggleViewAllNotes
											}
											showViewAllButton={true}
											title={
												<BaseText
													text={"Notes"}
													fontFamily="font-omnes-medium"
													tag="h1"
													size="text-sm"
													color="text-navy-500"
													additionalClasses=""
												/>
											}
											showBottomNoteCount={true}
											notesPlacementBottom={true}
											refetchData={() =>
												setRefetchData(
													(prevState) => !prevState
												)
											}
											notesListMaxHeight="max-h-[262px] overflow-scroll"
											salesOrderUuid={activeUuid}
											addNoteButtonStyles={"bg-white"}
										/>
									</BaseContainer>
								)}
								<BaseContainer
									columnContainerClasses="flex flex-col items-start w-full bg-white rounded-xl border border-gray-200 p-8 max-sm:p-2"
									columnShape="semi-rounded"
									borderColor="navy-200"
									isEnabled={true}
								>
									<ApprovalContentHeader
										dateOrder={dateOrder}
										fields={headerFields}
										orderNumber={orderNumber}
										orderStatus={orderStatus}
										submittedBy={submittedByUsers}
										hasNavigationCompassEditPermission={
											hasNavigationCompassEditPermission
										}
									/>
									<div className="border-b border-gray-200 w-full mt-3 mb-4"></div>
									<ApprovalContentSection
										leasePrices={leasePrices}
										fields={contentFields}
										notificationEmail={notificationEmail}
										assignedManager={assignedManager}
										orderFor={customerName}
										shipToAddress={{
											addressee: shipAddresse,
											attention: shipAttention,
											line1: shipLine1,
											line2: shipLine2,
											city: shipCity,
											state: { code: shipCode },
											zip: shipZip,
											phoneNumber: shipPhone,
										}}
										reasonForRequest={reasonForRequest}
										shippingInstructions={
											shippingInstructions
										}
										costCenter={costCenter}
										orderSummary={{
											tax: tax,
											total: total,
											shipping: shipping,
											subTotal: subTotal,
										}}
										shippingMethod={shippingMethod}
										shippingPriority={shippingPriority}
										orderTotalLease={orderTotalLease}
									/>
									<div className="border-b border-gray-200 w-full mt-3 mb-2"></div>
									<div
										className={`${
											items.length >= 1 &&
											"max-h-vh min-h-vh overflow-auto"
										} w-full `}
									>
										<ApprovalContentItems
											fields={dynamicContentFields}
											items={items}
										/>
									</div>
								</BaseContainer>
							</section>
						</div>

						{activeModal && activeUuid && (
							<SupplyPortal containerId="portal-root">
								<QuickActionModal
									isOpen={true}
									onClose={() => setActiveModal(null)}
									children={null}
									activeUuid={activeUuid}
									modalSelected={activeModal}
									setRefetchData={setRefetchData}
									refetchData={refetchData}
									updateRowData={updateRowData}
									setDeactivatedQuickMenuIds={
										setDeactivatedQuickMenuIds || []
									}
									handleBigModalClose={handleClose}
									onUpdate={handleRefetchData}
								/>
							</SupplyPortal>
						)}
						{isViewAllNotesOpen && activeUuid && (
							<SupplyPortal containerId="portal-root">
								<BaseModal
									containerClassNames="overflow-auto"
									header={<></>}
									isOpen={true}
									onClose={toggleViewAllNotes}
									children={
										<motion.div
											className="fixed inset-0 z-50 flex flex-col items-center justify-center"
											variants={modalVariants}
											initial="hidden"
											animate="visible"
											// onClick={handleBackgroundClick}
										>
											<BaseContainer
												columnContainerClasses="flex  w-[1020px] h-[800px] flex-col items-start bg-white p-5 rounded-xl border border-gray-200"
												columnShape="semi-rounded"
												borderColor="navy-200"
												isEnabled={true}
											>
												<div className="flex w-full items-center justify-between">
													<BaseText
														text={`Notes (${salesOrderNotes.length})`}
														fontFamily="font-omnes-medium"
														tag="h1"
														size="text-lg"
														color="text-navy-500"
														additionalClasses=""
													/>
													<BaseButton
														text=""
														color="white"
														fontColor="navy-300"
														borderColor="none"
														hoverFontColor="navy-500"
														additionalClasses=""
														size="sm"
														shape="rounded"
														iconLabel="Close"
														icon={getFontAwesomeIcon(
															"Xmark",
															"regular"
														)}
														as="button"
														onClick={() =>
															toggleViewAllNotes()
														}
														iconSize="text-lg pr-0 size-3"
													/>
												</div>

												<AddNotesForm
													notesData={adminNotes}
													openViewAllNotes={
														toggleViewAllNotes
													}
													showBottomNoteCount={false}
													notesPlacementBottom={false}
													refetchData={() =>
														setRefetchData(
															(prevState) =>
																!prevState
														)
													}
													notesListMaxHeight="h-[600px] overflow-scroll"
													salesOrderUuid={activeUuid}
													addNoteButtonStyles={
														"bg-gray-100"
													}
												/>
											</BaseContainer>
										</motion.div>
									}
								/>
							</SupplyPortal>
						)}
					</div>
				)}
				<>
					<AnimatePresence>
						{isEventLogOpen && (
							<motion.div
								initial={slideOutMenuVariants.initial}
								animate={slideOutMenuVariants.animate}
								exit={slideOutMenuVariants.exit}
								transition={slideOutMenuVariants.transition}
								className={slideOutMenuClassNames}
								style={{
									height: overlayHeight,
								}}
							>
								<>
									<div className={menuItemClassNames}>
										<EventLog
											title={eventLogTitle}
											isOpen={isEventLogOpen}
											parentData={orderDetails}
											slug={"sales-orders"}
											hasActivityToggle={true}
										/>
									</div>
								</>
							</motion.div>
						)}
					</AnimatePresence>
					<AnimatePresence>
						{isEventLogOpen && (
							<motion.div
								initial={slideOutMenuOverlayVariant.initial}
								animate={slideOutMenuOverlayVariant.animate}
								exit={slideOutMenuOverlayVariant.exit}
								transition={
									slideOutMenuOverlayVariant.transition
								}
								onClick={toggleMenu}
								className={overlayClassNames}
								style={{
									height: overlayHeight,
								}}
							></motion.div>
						)}
					</AnimatePresence>
				</>
			</div>
		)
	);
};

export default ApprovalViewModalContent;
