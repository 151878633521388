import { convertConstructorColumnTitles } from "../../utils";

export type SearchCriterion = {
	searchColumn: any;
	submittedSearchText: string;
};

type AdditionalData = {
	whereClause?: string;
};

type UseConstructUrlOptions = {
	slug: string;
	searchCriteria: SearchCriterion[];
	pageSize: number;
	sortColumn: Record<string, string>;
	columnMappings: any;
	page: number;
	pageMeta?: any;
	additionalData?: AdditionalData;
};

const useConstructUrl = ({
	slug,
	searchCriteria,
	pageSize,
	sortColumn,
	page,
	columnMappings,
	pageMeta,
	additionalData,
}: UseConstructUrlOptions): string => {
	let urlParts: string[] = [];
	let slugForUrl = slug;

	if (searchCriteria && searchCriteria.length > 0) {
		const convertedColumnMappings = convertConstructorColumnTitles(
			pageMeta,
			columnMappings
		);

		searchCriteria.forEach(({ searchColumn, submittedSearchText }) => {
			const columnNameToUpdate = convertedColumnMappings.find(
				(item: {
					settings: { field: string };
					labelSingular: string;
				}) => {
					const simplifiedItemSlug = item.labelSingular
						.replace(/\W+/g, "")
						.toLowerCase();
					const simplifiedHeader = searchColumn.Header.replace(
						/\W+/g,
						""
					).toLowerCase();

					return simplifiedItemSlug.includes(simplifiedHeader);
				}
			);

			if (!columnNameToUpdate) {
				console.error(
					"No column mapping found for the header:",
					searchColumn.Header
				);
				return;
			}

			const searchPart = `${slugForUrl}_${customEncodeURIComponent(
				columnNameToUpdate.slug
			)}=${customEncodeURIComponent(submittedSearchText)}`;
			urlParts.push(searchPart);
		});
	}

	urlParts.push(`${slug}_recordsPerPage=${pageSize}`);
	urlParts.push(`${slug}_page=${page}`);

	// Include additionalData, specifically the whereClause, if provided
	if (additionalData?.whereClause) {
		urlParts.push(
			`where=${customEncodeURIComponent(additionalData.whereClause)}`
		);
	}

	function customEncodeURIComponent(component: string): string {
		return encodeURIComponent(component).replace(/%2B/g, "+");
	}

	// Process sortColumn for all columns
	if (sortColumn && Object.keys(sortColumn).length > 0) {
		let sortIndex = 0; // Ensure indexes increment properly for multiple sorts
		Object.keys(sortColumn).forEach((columnKey) => {
			const sortParams = sortColumn[columnKey]; // Get the string value (e.g., "+number")

			if (sortParams) {
				// Find valid mapping for the columnKey (skip invalid keys like col)
				const columnSlug =
					columnMappings?.find(
						(mapping: { slug: string; field: string }) =>
							mapping.field === columnKey
					)?.slug || columnKey; // Fallback to columnKey if mapping is missing

				// Skip invalid keys (like "col")
				if (!columnMappings || !columnSlug || columnKey === "col") {
					console.error(
						`Skipping columnKey: ${columnKey} as it is invalid`
					);
					return; // Skip processing for this key
				}

				// Extract the prefix (+ or -) from the string value
				// const sortPrefix = sortParams[0]; // First character of the string

				// if (sortPrefix === "+" || sortPrefix === "-") {
				// 	// Construct the URL part using the proper slug and prefix
				// 	const sortPart = `${slugForUrl}_sort[${sortIndex}]=${customEncodeURIComponent(
				// 		`${sortPrefix}${columnSlug}`
				// 	)}`;
				// 	urlParts.push(sortPart);

				// 	// Increment sortIndex for the next sort
				// 	sortIndex++;
				// } else {
				// 	console.error(
				// 		`Invalid sort prefix: ${sortPrefix}. Expected '+' or '-'.`
				// 	);
				// }
			}
		});
	}

	// Construct the final baseUrl
	let baseUrl = `?${urlParts.join("&")}`;
	const modalOrders = localStorage.getItem("orders");
	if (modalOrders) {
		baseUrl += `&Orders=${modalOrders}`;
	}
	const modalUuid = localStorage.getItem("uuid");
	if (modalUuid) {
		baseUrl += `&uuid=${modalUuid}`;
	}

	return baseUrl;
};

export default useConstructUrl;
