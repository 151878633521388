import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface UseUrlSyncProps {
	previousUrl: string | null | undefined;
	setPreviousUrl?: React.Dispatch<
		React.SetStateAction<string | null | undefined>
	>;
	constructedUrl: string;
	currentPage: number;
	setCurrentPage: (page: number) => void;
	setSearchText: (text: string) => void;
	submittedSearchText: string;
	sortColumn: { [key: string]: string }; // Updated to handle multiple sorts
	pageSize: number;
	updateUrlWithNewPage: (newPage: number) => void;
	slug: string;
	setRecordsPerPageAfterChange: (recordsPerPage: number) => void;
}

const useUrlSync = ({
	previousUrl,
	setPreviousUrl,
	constructedUrl,
	currentPage,
	setCurrentPage,
	setSearchText,
	submittedSearchText,
	sortColumn,
	pageSize,
	updateUrlWithNewPage,
	slug,
	setRecordsPerPageAfterChange,
}: UseUrlSyncProps) => {
	const navigate = useNavigate();

	useEffect(() => {
		const updateUrlWithSortParams = () => {
			// Parse the previous URL if available
			const existingParams = previousUrl
				? new URLSearchParams(
						new URL(previousUrl, window.location.origin).search
				  )
				: new URLSearchParams();

			// Parse the new constructed URL
			const newParams = new URLSearchParams(
				new URL(constructedUrl, window.location.origin).search
			);

			// Check localStorage for activeSortColumns
			const storedSortColumns = JSON.parse(
				localStorage.getItem("activeSortColumns") || "[]"
			) as { columnId: string; direction: "asc" | "desc" }[];

			// Generate sort parameters from localStorage
			const mergedSortParams: string[] = storedSortColumns.map(
				({ columnId, direction }, index) => {
					const sortPrefix = direction === "desc" ? "-" : "+";
					return `${slug}_sort[${index}]=${sortPrefix}${columnId}`;
				}
			);

			// Add non-sort parameters from `constructedUrl`
			newParams.forEach((value, key) => {
				if (!key.includes("_sort"))
					mergedSortParams.push(`${key}=${value}`);
			});

			// Construct the final URL with merged parameters
			const finalParams = new URLSearchParams();
			mergedSortParams.forEach((param) => {
				const [key, value] = param.split("=");
				finalParams.set(key, value);
			});

			// Finalize and navigate to the new URL
			const finalUrl = `?${finalParams.toString()}`;
			navigate(finalUrl, { replace: true });
		};

		if (sortColumn && Object.keys(sortColumn).length > 0) {
			updateUrlWithSortParams();
		} else if (!previousUrl) {
			// If no previous URL, navigate to the constructed URL
			navigate(constructedUrl);
		}

		// Update state and manage URL sync
		setCurrentPage(currentPage);
		setPreviousUrl?.(constructedUrl);
		setSearchText("");
	}, [
		submittedSearchText,
		sortColumn,
		pageSize,
		updateUrlWithNewPage,
		slug,
		previousUrl,
		constructedUrl,
		currentPage,
		setCurrentPage,
		setSearchText,
		setPreviousUrl,
		setRecordsPerPageAfterChange,
		navigate,
	]);
};

export default useUrlSync;
