// GENERAL
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

// COMPONENTS
import BaseModal from "../../../../../components/layout/Modal";
import { Guardrail } from "../../../types";
import {
	BaseButton,
	BaseContainer,
	BaseText,
} from "../../../../../components/ui";
import ToggleButton from "../../../../../components/ui/ToggleButton";
import ModalContentSection from "../../../../../components/layout/Modal/ModalContentSection";
import Image from "../../../../../components/layout/ImageComponent";
import { LoadingMessage } from "../../../../../components/misc";
import SupplyFormGuardrail from "../../../../../components/misc/SupplyFormGuardrail";

// UTILS, HOOKS, API, ASSETS, TYPES
import {
	getFontAwesomeIcon,
	handleGuardrailCloseAction,
} from "../../../../../utils";
import placeholderImage from "../../../../../assets/placeholder-no-image-available.png";
import { createItem, getData } from "../../../api/itemsApi";
import dummyCreateItemFields from "../../modals/create/DUMMYCREATEITEMFIELDS.json";
import {
	CreateItemFormData,
	RestrictToPersona,
	CreateItemModalProps,
	Persona,
} from "../../../types";
import { useToaster } from "../../../../../hooks/useToasterContext";
import SupplyPortal from "../../../../../components/misc/SupplyPortal";

const CreateItemModal = ({
	isOpen,
	handleListingModalClick,
}: CreateItemModalProps) => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const [guardrail, setGuardrail] = useState<Guardrail | null>(null);
	const [toggleStatus, setToggleStatus] = useState<boolean>(true);
	const [selectsData, setSelectsData] = useState<Record<string, any> | null>(
		null
	);
	const [data, setData] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [_, setErrors] = useState<string | null>(null);
	const [hasErrors, setHasErrors] = useState(false);
	const formMethods = useForm<CreateItemFormData>({
		mode: "all",
		defaultValues: {},
	});
	const { addToaster, clearAllToasters } = useToaster();
	const {
		reset,
		handleSubmit,
		clearErrors,
		setError,
		formState: { dirtyFields, errors, isValid },
	} = formMethods;

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const [
					manufacturerData,
					itemTypeData,
					departmentsData,
					itemCategory,
					unitOfMeasures,
					priceTypes,
					personas,
				] = await Promise.all([
					getData("manufacturers"),
					getData("item-types"),
					getData("asset-types"),
					getData("item-categories"),
					getData("measures"),
					getData("price-types", ["uuid", "name"]),
					getData("personas", ["uuid", "name", "number"]),
				]);

				const formatPersonas = personas.map(
					({ uuid, number, name }: Persona) => ({
						uuid,
						name: `${number} - ${name}`,
					})
				);

				// For the default purchase method drop down, the options include 'each', 'lease-48-months', and
				// 'lease-36-months' but we want to exclude the 36 month option - filtering that out via the uuid
				const formatPriceTypes = priceTypes
					.filter(
						({ uuid }) =>
							uuid !== "1b5a09bf-13a1-11ef-8e3b-0aae2980db55"
					)
					.map(({ uuid, name }) => ({
						uuid: uuid,
						name: name,
					}));

				const inventoryType = [
					{ uuid: "SERIALIZED", name: "SERIALIZED" },
					{ uuid: "Year", name: "NON-SERIALIZED" },
					{ uuid: "N/A", name: "HYBRID" },
				];

				setSelectsData({
					manufacturerData,
					itemTypeData,
					assetTypeData: departmentsData,
					itemCategory: itemCategory,
					unitOfMeasures,
					priceTypes: formatPriceTypes,
					inventoryType,
					formatPersonas,
				});

				setErrors(null);
			} catch (error) {
				console.error("Error while fetching data:", error);
				setErrors("An error occurred while fetching data");
				setData(null);
			} finally {
				setIsLoading(false);
			}
		};

		if (isOpen) {
			fetchData();
			setIsMenuOpen(true);
		}
	}, [isOpen]);

	const onFormSubmit = async (data: CreateItemFormData) => {
		let personaItems;
		if (data.restrictToPersona && data.restrictToPersona.length > 0) {
			personaItems = data.restrictToPersona.map(
				({ uuid }: RestrictToPersona) => ({
					persona: {
						uuid,
					},
				})
			);
		}

		if (
			data.defaultPriceType.name === "Lease" &&
			(data._leasePrice === "" || data._leasePrice === null)
		) {
			setError("_leasePrice", {
				type: "required",
				message: "Required field.",
			});
		} else if (
			data.defaultPriceType.name === "Each" &&
			(data._unitPrice === "" || data._unitPrice === null)
		) {
			setError("_unitPrice", {
				type: "required",
				message: "Required field.",
			});
		} else {
			const { restrictToPersona, ...restData } = data;

			const newData = {
				...restData,
				isActive: toggleStatus,
				catalog: { name: "Compass" },
				...(personaItems && { personaItems }),
			};

			const response = await createItem(newData);

			if (response.status === 201) {
				addToaster({
					isOpen: true,
					message: "Item created",
					status: "success",
				});
			} else {
				addToaster({
					isOpen: true,
					message: "An error took place",
					status: "error",
				});
			}
			setIsMenuOpen(false);
			reset();
			setGuardrail(null);
			handleListingModalClick();
			document.body.classList.remove("overflow-hidden");
		}
	};
	const updateGuardrails = (newGuardrail: Guardrail) => {
		setGuardrail(newGuardrail);
	};
	const handleCloseAction = () => {
		handleGuardrailCloseAction(
			dirtyFields,
			updateGuardrails,
			reset,
			clearAllToasters,
			setGuardrail,
			handleListingModalClick,
			"Do you want to create this new item?",
			"Your new item will be lost if you don’t create it.",
			"Don't Create",
			"Cancel",
			"Create Item"
		);
	};

	const handleGuardrailSave = () => {
		handleSubmit(onFormSubmit)();
		setGuardrail(null);
	};

	const handleGuardrailDoNotSave = () => {
		reset();
		setGuardrail(null);
		setHasErrors(false);
		clearErrors();
		handleListingModalClick();
		document.body.classList.remove("overflow-hidden");
	};

	useEffect(() => {
		const containsErrors = Object.keys(errors).length > 0;

		if (containsErrors) {
			setHasErrors(true);
		} else {
			setHasErrors(false);
		}
	}, [errors]);

	const formClassNames =
		"flex justify-around w-full max-md:flex-col min-h-[1000px] gap-10";
	const modalContainerClassNames =
		"fixed inset-0 flex items-end justify-center z-50 max-w-[1200px] mx-auto modal-bottom z-[9920]";
	const modalBorderClassNames = `modal-box min-w-wideModal min-h-[95dvh] p-0  ${
		isMenuOpen ? " overflow-hidden pt-0" : ` pt-0 `
	}`;
	const modalOverLayClassNames = "fixed inset-0 bg-black z-[9910]";
	// HEADER CLASSES:
	const headerTitleAndButtonsContainerClassNames =
		"flex items-start max-lg:flex-col justify-between w-full pb-2";
	const headerTitlesContainerClassNames =
		"flex w-2/3 max-lg:w-full max-lg:ml-1 bg-white justify-between";
	const headerButtonContainerClassNames =
		"flex w-1/3 justify-end  max-lg:pt-3 max-lg:justify-start";
	const guardrailBackgroundClassNames =
		"bg-black opacity-10 absolute z-[9920] w-full left-0 top-0 ";

	return (
		<AnimatePresence>
			{isOpen && (
				<>
					<motion.div
						className={modalContainerClassNames}
						initial={{ y: "100vh" }}
						animate={{ y: "-3vh" }}
						exit={{ y: "100vh" }}
						transition={{
							duration: 0.1,
							type: "spring",
							stiffness: 100,
							damping: 15,
						}}
					>
						<div className="min-w-full">
							<div className={modalBorderClassNames}>
								{isLoading || (!data && !selectsData) ? (
									<div className="flex justify-center items-center min-h-svh w-full">
										<LoadingMessage message={""} />
									</div>
								) : (
									<motion.div>
										{guardrail && (
											<SupplyPortal containerId="portal-root">
												<div
													className={
														guardrailBackgroundClassNames
													}
													style={{
														height: "100vh",
														width: "100vw",
													}}
												>
													<SupplyFormGuardrail
														messages={guardrail}
														buttonText={guardrail}
														onDoNotSave={
															handleGuardrailDoNotSave
														}
														onCancel={() =>
															setGuardrail(null)
														}
														onSave={
															handleGuardrailSave
														}
													/>
												</div>
											</SupplyPortal>
										)}
										<BaseModal
											containerClassNames="bg-white overflow-auto  min-h-[100%] w-full max-h-[80%] p-5"
											header={
												<>
													<div
														className={
															headerTitleAndButtonsContainerClassNames
														}
													>
														<div
															className={
																headerTitlesContainerClassNames
															}
														>
															<BaseText
																size={"text-xl"}
																color={""}
																text={
																	"Create  Item"
																}
																tag={"h2"}
																fontFamily="font-medium"
															/>
															<div className="hidden max-lg:flex">
																{
																	<BaseButton
																		text=""
																		color="white"
																		fontColor="navy-300"
																		borderColor="none"
																		hoverFontColor="navy-500"
																		additionalClasses="p-2 mx-4px"
																		size="sm"
																		shape="rounded"
																		iconLabel="Close"
																		icon={getFontAwesomeIcon(
																			"Xmark",
																			"regular"
																		)}
																		as="button"
																		onClick={
																			handleCloseAction
																		}
																		iconSize="text-lg pr-0 size-3"
																	/>
																}
															</div>
														</div>
														<div
															className={
																headerButtonContainerClassNames
															}
														>
															{
																<ToggleButton
																	initialStatus={
																		toggleStatus
																	}
																	onClick={() => {
																		setToggleStatus(
																			!toggleStatus
																		);
																	}}
																	textPosition={
																		"right"
																	}
																	textSize={
																		"md"
																	}
																	activeColorBackground="bg-mint-600"
																	inactiveColorBackground="bg-crimson-500"
																	activeTextColor="text-mint-700"
																	inactiveTextColor="text-crimson-700"
																	hasDisabledStatus={
																		false
																	}
																	additionalClasses={`w-1/4 py-2`}
																	hasDivider={
																		true
																	}
																	fontFamily="omnes-medium"
																	activeLabel={
																		"Active"
																	}
																	inactiveLabel={
																		"Inactive"
																	}
																	id={
																		"isActive"
																	}
																	pillHeight="h-7"
																	smallToggle={
																		false
																	}
																	borderStyle={
																		false
																	}
																/>
															}
															<div className="flex flex-col">
																<div className="flex">
																	<BaseButton
																		text="Cancel"
																		color="white"
																		hoverBackground="primaryHoverLight"
																		fontColor="primary"
																		borderColor="primary"
																		additionalClasses={`py-1 px-3 mx-2 max-sm:mx-1`}
																		size="sm"
																		shape="rounded"
																		as="button"
																		onClick={() =>
																			handleCloseAction()
																		}
																		fontFamily="omnes-medium"
																		customWidth="w-67px"
																		customHeight="h-26px"
																		isDisabled={
																			false
																		}
																	/>

																	<BaseButton
																		type="button"
																		text="Create Item"
																		color="primary"
																		fontColor="white"
																		hoverBackground="primaryHover"
																		borderColor="none"
																		additionalClasses={`py-1 px-3 mx-2 max-sm:mx-1 text-nowrap`}
																		size="sm"
																		shape="rounded"
																		as="button"
																		fontFamily="omnes-medium"
																		onClick={handleSubmit(
																			onFormSubmit
																		)}
																		customWidth=""
																		customHeight="h-26px"
																		isDisabled={
																			!isValid
																		}
																	/>
																	<div className="flex max-lg:hidden">
																		{
																			<BaseButton
																				text=""
																				color="white"
																				fontColor="navy-300"
																				borderColor="none"
																				hoverFontColor="navy-500"
																				additionalClasses="p-2 mx-4px"
																				size="sm"
																				shape="rounded"
																				iconLabel="Close"
																				icon={getFontAwesomeIcon(
																					"Xmark",
																					"regular"
																				)}
																				as="button"
																				onClick={
																					handleCloseAction
																				}
																				iconSize="text-lg pr-0 size-3"
																			/>
																		}
																	</div>
																</div>
																{hasErrors && (
																	<div className="flex items-start">
																		<p className="text-xs text-crimson-700">
																			Please
																			review
																			your
																			details
																			and
																			try
																			again.
																		</p>
																	</div>
																)}
															</div>
														</div>
													</div>
												</>
											}
											isOpen={isOpen}
											onClose={handleListingModalClick}
											children={
												<div className="bg-white px-5">
													<AnimatePresence>
														<motion.div
															key="motionDiv2"
															initial={{
																opacity: 0,
															}}
															animate={{
																opacity: 1,
															}}
															transition={{
																ease: "easeIn",
																duration: 0.4,
															}}
														>
															{/* FormProvider is providing context, allows BaseInput modal to access form methods */}
															<FormProvider
																{...formMethods}
															>
																<form
																	className={
																		formClassNames
																	}
																	onSubmit={handleSubmit(
																		onFormSubmit
																	)}
																>
																	<BaseContainer
																		columnContainerClasses="flex flex-col w-2/5 max-md:w-full mb-30"
																		columnShape="none"
																		borderColor="transparent"
																		isEnabled={
																			true
																		}
																		children={
																			<>
																				<div className="flex items-center justify-center flex-col mb-6">
																					<Image
																						imageUrl={
																							placeholderImage
																						}
																						altText="No Image Available"
																						className="items-center size-54"
																					/>
																				</div>

																				<ModalContentSection
																					sectionTitle={
																						<BaseText
																							size={
																								"text-md"
																							}
																							color={
																								"text-slate-950"
																							}
																							text={
																								"Overview"
																							}
																							fontFamily={
																								"font-omnes-medium"
																							}
																							tag={
																								"h2"
																							}
																							additionalClasses="pb-1"
																						/>
																					}
																					valueType="input"
																					data={[]}
																					dataLabel="OVERVIEW"
																					fields={
																						dummyCreateItemFields
																							.DUMMYCREATEITEMFIELDS[0]
																							.OVERVIEW
																					}
																					selectOptions={
																						selectsData
																					}
																					itemContainerClasses="flex w-full"
																					sectionContainerClasses="flex flex-col items-start w-full py-2"
																					detailsContainerClasses="grid grid-cols-2 items-start w-full"
																					mobileBreakpoint="max-md:flex-col"
																				/>
																			</>
																		}
																	/>
																	<BaseContainer
																		columnContainerClasses="flex flex-col w-3/5 max-md:w-full h-full py-2.5 px-1.5"
																		backgroundColor="bg-navy-100"
																		borderColor="transparent"
																		columnShape="rounded-full"
																		isEnabled={
																			true
																		}
																		children={
																			<>
																				<ModalContentSection
																					splitOptionsIndices={{
																						firstSection:
																							[
																								0,
																							],
																						secondSection:
																							[
																								1,
																								2,
																								3,
																							],
																					}}
																					sectionTitle={
																						<div className="flex  justify-between">
																							<BaseText
																								size={
																									"text-md"
																								}
																								color={
																									"text-slate-950"
																								}
																								text={
																									"Pricing"
																								}
																								fontFamily={
																									"font-omnes-medium"
																								}
																								tag={
																									"h2"
																								}
																								additionalClasses="pt-0 pb-1"
																							/>
																						</div>
																					}
																					valueType="input"
																					data={[]}
																					dataLabel="PRICING"
																					fields={
																						dummyCreateItemFields
																							.DUMMYCREATEITEMFIELDS[1]
																							.PRICING
																					}
																					selectOptions={
																						selectsData
																					}
																					itemContainerClasses="flex w-full"
																					sectionContainerClasses="flex flex-col items-start w-full py-2 px-4"
																					detailsContainerClasses="flex  flex-col w-full "
																					mobileBreakpoint="max-md:flex-col"
																				/>

																				<ModalContentSection
																					sectionTitle={
																						<BaseText
																							size={
																								"text-md"
																							}
																							color={
																								"text-slate-950"
																							}
																							text={
																								"Attributes"
																							}
																							fontFamily={
																								"font-omnes-medium"
																							}
																							tag={
																								"h2"
																							}
																							additionalClasses="pb-1"
																						/>
																					}
																					valueType="input"
																					data={{}}
																					dataLabel="ATTRIBUTES"
																					fields={
																						dummyCreateItemFields
																							.DUMMYCREATEITEMFIELDS[2]
																							.ATTRIBUTES
																					}
																					selectOptions={
																						selectsData
																					}
																					itemContainerClasses="flex w-full"
																					sectionContainerClasses="flex flex-col items-start w-full py-2 px-4"
																					detailsContainerClasses="grid grid-cols-2 items-start w-full"
																					mobileBreakpoint="max-md:flex-col"
																				/>
																				<ModalContentSection
																					sectionTitle={
																						<BaseText
																							size={
																								"text-md"
																							}
																							color={
																								"text-slate-950"
																							}
																							text={
																								"Content"
																							}
																							fontFamily={
																								"font-omnes-medium"
																							}
																							tag={
																								"h2"
																							}
																							additionalClasses="pb-1"
																						/>
																					}
																					valueType="input"
																					data={[]}
																					dataLabel="CONTENT"
																					fields={
																						dummyCreateItemFields
																							.DUMMYCREATEITEMFIELDS[3]
																							.CONTENT
																					}
																					selectOptions={
																						selectsData
																					}
																					itemContainerClasses="flex w-full"
																					sectionContainerClasses="flex flex-col items-start w-full py-2 px-4"
																					detailsContainerClasses="flex flex-col items-start w-full"
																					mobileBreakpoint="max-md:flex-col"
																				/>
																				<ModalContentSection
																					sectionTitle={
																						<BaseText
																							size={
																								"text-md"
																							}
																							color={
																								"text-slate-950"
																							}
																							text={
																								"More Info"
																							}
																							fontFamily={
																								"font-omnes-medium"
																							}
																							tag={
																								"h2"
																							}
																							additionalClasses="pb-1"
																						/>
																					}
																					valueType="input"
																					data={[]}
																					dataLabel="MOREINFO"
																					fields={
																						dummyCreateItemFields
																							.DUMMYCREATEITEMFIELDS[4]
																							.MOREINFO
																					}
																					selectOptions={
																						selectsData
																					}
																					itemContainerClasses="flex w-full"
																					sectionContainerClasses="flex flex-col items-start w-full py-2 px-4"
																					detailsContainerClasses="flex flex-col items-start w-full"
																					mobileBreakpoint="max-md:flex-col"
																				/>
																			</>
																		}
																	/>
																</form>
															</FormProvider>
														</motion.div>
													</AnimatePresence>
												</div>
											}
										/>
									</motion.div>
								)}
							</div>
						</div>
					</motion.div>
					<>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 0.3 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.3 }}
							className={modalOverLayClassNames}
						></motion.div>
					</>
				</>
			)}
		</AnimatePresence>
	);
};

export default CreateItemModal;
