import React, { useEffect, useRef, useState } from "react";
import { getFontAwesomeIcon } from "../../utils";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOutVariants } from "../../motionVariants";
import SingleLineShimmer from "./Shimmer";

interface AdvancedInputProps {
	label: string;
	onSelect: (user: any) => void;
	fetchMoreData: () => Promise<void>;
	data: any[]; // The list of options to be rendered, passed in as a prop
	isFetchingMore: boolean; // Loading state for the 'load more' functionality
	hasMorePages: boolean; // If there are more pages to fetch
	onClearInput: () => void;
	findSpecificValue: (value: string) => Promise<any>; // Updated type
}

interface GenericOption {
	id: string;
	value: string;
	[key: string]: any;
}

const AdvancedInput: React.FC<AdvancedInputProps> = ({
	label,
	onSelect,
	fetchMoreData,
	data,
	isFetchingMore,
	hasMorePages,
	onClearInput,
	findSpecificValue,
}) => {
	const parentRef = useRef<HTMLDivElement>(null);
	const sentinelRef = useRef<HTMLDivElement>(null);
	const [inputValue, setInputValue] = useState<string>(""); // Ensure inputValue is always a string
	const [filteredOptions, setFilteredOptions] = useState<GenericOption[]>([]); // Typed as GenericOption[]
	const [showDropdown, setShowDropdown] = useState<boolean>(true); // Toggle dropdown visibility
	const debounceTimerRef = useRef<NodeJS.Timeout | null>(null); // Ref to store the debounce timer
	const abortControllerRef = useRef<AbortController | null>(null); // Ref to store the AbortController
	const isCancelled = useRef(false);
	const [searchLoading, setSearchLoading] = useState<boolean>(false);
	// Handle input changes with debounce and API call
	useEffect(() => {
		// Reset the cancellation flag
		if (inputValue.length < 3) {
			setSearchLoading(true);
		}
		isCancelled.current = false;

		// Clear any existing debounce timer
		if (debounceTimerRef.current) {
			clearTimeout(debounceTimerRef.current);
		}
		// Abort any ongoing API call
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
		// Do not make the API call if input length is less than 3
		if (inputValue.length < 3) {
			setFilteredOptions([]); // Clear the options if input is too short
			return;
		}

		// Create a new AbortController for the new API call
		const abortController = new AbortController();
		abortControllerRef.current = abortController;

		// Start a new debounce timer
		debounceTimerRef.current = setTimeout(() => {
			if (isCancelled.current) {
				console.log("Debounce timer cancelled, not making API call");
				return;
			}

			// Call findSpecificValue with the appropriate arguments
			const fetchData = async () => {
				try {
					const result = await findSpecificValue(inputValue);
					// Check if the fetch was aborted
					if (abortControllerRef.current?.signal.aborted) {
						console.log("Fetch aborted");
						return;
					}
					// Update the filteredOptions state with the fetched data
					const options = result.map((option: any) => ({
						...option,
						id: option.uuid,
						value: option._name,
					}));
					setFilteredOptions(options);
				} catch (error) {
					if (abortControllerRef.current?.signal.aborted) {
						console.log("Fetch aborted");
					} else {
						console.error("Error fetching data:", error);
					}
				}
			};
			setSearchLoading(false);
			fetchData();
		}, 1000); // Wait for 1 seconds

		// Cleanup function
		return () => {
			// Set the cancellation flag
			isCancelled.current = true;

			// Clear the debounce timer when inputValue changes or component unmounts
			if (debounceTimerRef.current) {
				clearTimeout(debounceTimerRef.current);
			}

			// Abort any ongoing API call
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
		};
	}, [inputValue, findSpecificValue]);

	// Update filteredOptions whenever data or inputValue changes
	useEffect(() => {
		if (inputValue.length < 3) {
			// When inputValue is less than 3 characters, use data prop
			setFilteredOptions([]);
		}
	}, [data, inputValue]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setInputValue(value);

		if (value.length === 0) {
			clearInput();
		}
		setShowDropdown(true); // Show dropdown when typing
	};

	const clearInput = () => {
		setInputValue(""); // Clear input field
		setFilteredOptions([]); // Clear filtered options
		if (onClearInput) {
			onClearInput(); // Notify parent to reset data
		}

		// Abort any ongoing API call when input is cleared
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
	};

	// Intersection Observer logic for infinite scroll
	useEffect(() => {
		if (showDropdown) {
			const observer = new IntersectionObserver(
				(entries) => {
					const [entry] = entries;
					if (
						entry.isIntersecting &&
						!isFetchingMore &&
						hasMorePages &&
						inputValue.length < 3
					) {
						fetchMoreData();
					}
				},
				{
					root: parentRef.current,
					rootMargin: "0px",
					threshold: 1.0,
				}
			);

			const sentinel = sentinelRef.current;
			if (sentinel) observer.observe(sentinel);

			return () => {
				if (sentinel) observer.unobserve(sentinel);
				observer.disconnect();
			};
		}
	}, [
		showDropdown,
		fetchMoreData,
		isFetchingMore,
		hasMorePages,
		inputValue.length,
	]);

	const handleOptionSelect = (option: GenericOption) => {
		console.log(option, "selected");
		setInputValue(`${option.value} (${option.email})`); // Use option.value
		setShowDropdown(false); // Hide the dropdown after selection
		if (onSelect) {
			onSelect(option); // Call the callback function with the selected option
		}

		// Abort any ongoing API call when an option is selected
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
	};

	// Prepare options to display
	const options: GenericOption[] =
		inputValue.length >= 3
			? filteredOptions
			: data.map((option) => ({
					...option,
					id: option.uuid,
					value: option._name,
			  }));

	const shimmerCount = 4;

	return (
		<div className="relative mt-5">
			{label && (
				<label className="absolute bottom-11 block text-sm text-omnes-medium text-gray-900 mb-1">
					{label}
				</label>
			)}

			<div
				className={`flex items-center border ${
					inputValue ? "border-green-500" : "border-blue-500"
				} rounded relative`}
			>
				<input
					type="text"
					value={inputValue || ""} // Ensure value is always a string
					onChange={handleInputChange}
					onFocus={() => setShowDropdown(true)}
					placeholder="e.g. 'John Doe'"
					autoComplete="off"
					className="w-full h-10 px-3 border border-gray-300 rounded"
					onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
				/>

				<div
					className={`absolute right-10 top-3 ${
						inputValue
							? "text-black cursor-pointer"
							: "text-gray-300 pointer-events-none"
					}`}
					onClick={inputValue ? clearInput : undefined}
				>
					{getFontAwesomeIcon("times", "solid")}
				</div>

				<div className="text-blue-500 absolute right-4 top-3">
					{getFontAwesomeIcon("chevronDown", "solid")}
				</div>
			</div>

			<AnimatePresence mode="wait">
				{showDropdown && (
					<motion.div
						ref={parentRef}
						className={`${
							searchLoading ? "" : "border-gray-300"
						} absolute w-full max-h-40 overflow-y-auto border  bg-white z-50`}
						variants={fadeInOutVariants}
						initial="initial"
						animate="animate"
						exit="exit"
					>
						{options.length > 0
							? options.map((option) => (
									<div
										key={option.id}
										onClick={() =>
											handleOptionSelect(option)
										}
										className="p-2 cursor-pointer border-b border-gray-100 flex justify-between items-center"
									>
										<span>
											{option.value} ({option.email})
										</span>
									</div>
							  ))
							: !isFetchingMore &&
							  !searchLoading && (
									<div className="p-2 text-gray-500 text-center">
										No options found
									</div>
							  )}

						{/* Infinite scroll loader */}
						{isFetchingMore && hasMorePages && (
							<motion.div
								className="relative flex flex-col items-start justify-center text-primary min-h-14 isolate overflow-hidden"
								variants={fadeInOutVariants}
								initial="initial"
								animate="animate"
								exit="exit"
							>
								{Array.from({ length: shimmerCount }).map(
									(_, index) => (
										<SingleLineShimmer
											key={index}
											randomWidth
											minWidth={2}
											maxWidth={4}
											widthFraction={10}
											height="h-4"
											hasBorderBottom
											borderColor="border-gray-200"
											color={"bg-gray-100"}
										/>
									)
								)}
							</motion.div>
						)}

						<div
							ref={sentinelRef}
							className={`${searchLoading ? "" : "h-1"}`}
						></div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default AdvancedInput;
