import React, { ReactNode } from "react";
import BaseDetailRow from "../../ui/BaseDetailRow";
import BaseInput from "../../ui/BaseInput/BaseInput";
import { Fragment } from "react";
type GenericData = {
	[dataLabel: string]: object;
};

type PricingLogic = {
	[key: string]: {
		[key: string]: {
			value?: string;
			inputType?: string;
		};
	};
};

interface Field {
	id: string;
	labelIcon?: string;
	label: string;
	valueKey: string;
	inputType?: string;
	labelTextClasses?: string;
	valueTextClasses?: string;
	hasValueLeftIcon?: string;
	hasValueRightIcon?: string;
	hasValueImage?: string;
	detailGridClasses?: string;
	inputGridClasses?: string;
	additionalInputContainerClasses?: string;
	isBoolean?: boolean;
	truthyValue?: string;
	falsyValue?: string;
	isConcatenated?: string;
	isEditOnly?: boolean;
	inputHasLeftIcon?: string;
	inputIconClasses?: string;
	disabledClasses?: string;
	detailIconClasses?: string;
	secondValueKey?: string;
	isPrice?: boolean;
	pricing?: object;
}

interface ModalContentSectionTypes<T extends GenericData> {
	data: T;
	dataLabel: string;
	fields?: any;
	itemContainerClasses?: string;
	iconStyle?: "solid" | "regular" | "light";
	mobileBreakpoint?: string;
	sectionContainerClasses?: string;
	valueType: "detail" | "input";
	inputToggle?: boolean;
	toggleOnClick?: (
		e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
	) => void;
	sectionTitle: ReactNode;
	detailsContainerClasses?: string;
	isEnabled?: boolean;
	onInputChange?: (inputKey: string, value: any) => void;
	selectOptions?: any;
	// when splitOptionsIndices are provided the component will split the items in the arrays between one of two radio buttons.
	splitOptionsIndices?: { firstSection: number[]; secondSection: number[] };
	menuPlacement?: string;
}

const selectOptionsMapping: { [key: string]: string } = {
	assetType: "assetTypeData",
	manufacturer: "manufacturerData",
	itemCategory: "itemCategory",
	inventoryType: "inventoryType",
	itemType: "itemTypeData",
	vendor: "vendorsData",
	overridePoSubmissionIntegration: "overridePoSubmissionIntegrationMethods",
	overrideAsnTransmissionIntegration:
		"overridePoSubmissionIntegrationMethods",
	restrictToPersona: "formatPersonas",
	item: "itemsData",
	defaultPriceType: "priceTypes",
};

const ModalContentSection = <T extends { [key: string]: any }>({
	sectionTitle,
	data,
	dataLabel,
	fields,
	itemContainerClasses,
	iconStyle,
	mobileBreakpoint,
	sectionContainerClasses,
	valueType,
	toggleOnClick,
	detailsContainerClasses,
	isEnabled,
	selectOptions,
	menuPlacement,
}: ModalContentSectionTypes<T>) => {
	const sectionContent = fields?.map((field: any) => {
		let options;
		let value = field.valueKey
			.split(".")
			.reduce(
				(accum: any, currentValue: any) =>
					accum ? accum[currentValue] : undefined,
				data
			);

		// formatting price, null/undefined/empty values,
		// and hardcoding defaultPriceType to each
		if (field.isPrice) {
			value = value === 0 ? "0" : value || "—";
		} else if (
			!field.isBoolean &&
			!field.isPrice &&
			field.valueKey != "defaultPriceType" &&
			valueType === "detail"
		) {
			value =
				value === 0
					? "0"
					: value !== undefined && value !== null && value !== ""
					? value
					: "—";
		}

		// formatting double values in view and edit mode
		if (field.inputType === "select" && valueType === "detail") {
			value = value?.name || "—";
		} else if (
			field.inputType === "select" &&
			valueType === "input" &&
			value
		) {
			value = {
				uuid: value.uuid,
				name: value.name,
			};
		}

		//  assigns the correct options to the appropriate input using
		// the selectOptionsMapping object above
		if (
			valueType === "input" &&
			selectOptions &&
			field.valueKey !== "overridePoSubmissionIntegration"
		) {
			const selectOptionKey = selectOptionsMapping[field.valueKey];
			options = selectOptions[selectOptionKey];
		} else if (
			field.valueKey === "overridePoSubmissionIntegration" &&
			selectOptions &&
			Array.isArray(
				selectOptions.overridePoSubmissionIntegrationMethods
			) &&
			selectOptions.overridePoSubmissionIntegrationMethods.length > 0 &&
			valueType === "input"
		) {
			options = selectOptions.overridePoSubmissionIntegrationMethods;
			field.inputType = "select";
		} else if (
			field.valueKey === "overridePoSubmissionIntegration" &&
			(!selectOptions ||
				!Array.isArray(
					selectOptions.overridePoSubmissionIntegrationMethods
				) ||
				selectOptions.overridePoSubmissionIntegrationMethods.length ===
					0) &&
			valueType === "input"
		) {
			field.inputType = "disabledSelect";
		}

		if (valueType === "detail") {
			return (
				<Fragment key={field.id}>
					<BaseDetailRow
						dataItem={value}
						allData={data}
						field={field}
						allFields={fields}
						containerWidth={itemContainerClasses}
						mobileBreakpoint={mobileBreakpoint}
						iconStyle={iconStyle}
						isEnabled={isEnabled}
						textSize="text-sm"
					/>
				</Fragment>
			);
		} else if (
			valueType === "input" &&
			field.secondValueKey &&
			field.inputType === "none"
		) {
			return (
				<Fragment key={field.id}>
					<BaseDetailRow
						dataItem={value}
						allData={data}
						field={field}
						allFields={fields}
						containerWidth={itemContainerClasses}
						mobileBreakpoint={mobileBreakpoint}
						iconStyle={iconStyle}
						isEnabled={isEnabled}
						textSize="text-sm"
					/>
				</Fragment>
			);
		} else {
			return (
				<Fragment key={field.id}>
					<BaseInput
						dataItem={value}
						allData={data}
						field={field}
						allFields={fields}
						inputContainerClasses={itemContainerClasses}
						onClick={toggleOnClick}
						options={options}
						textSize="text-sm"
						menuPlacement={menuPlacement}
						hasDirtyValidation={true}
					/>
				</Fragment>
			);
		}
	});

	return (
		<section className={`${sectionContainerClasses}`}>
			{sectionTitle}
			<div className={`${detailsContainerClasses}`}>{sectionContent}</div>
		</section>
	);
};

export default ModalContentSection;
