import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { motion } from "framer-motion";
// HOOKS
import {
	formatDateMontDayYearTime,
	getFontAwesomeIcon,
} from "../../../../utils";
import { useItemsContext, usePrivilegeCheck } from "../../../../hooks";
import formatDate from "../../../../utils/formatDateForDetailsBox";
import formatCurrency from "../../../../utils/formatCurrency";

import {
	getAdmins,
	getAdminsAndManagers,
	updateSalesOrder,
	denySalesOrder,
	updateUserAssignment,
	getSalesOrderItems,
	getSalesOrderDetails,
	getSearchValuesFromAdvancedInput,
} from "../api/OrdersApi";
import { useToaster } from "../../../../hooks/useToasterContext";
// COMPONENTS
import { LoadingMessage } from "../../../../components/misc";
import BaseText from "../../../../components/ui/BaseText";
import { BaseButton, BaseInput, BaseToolTip } from "../../../../components/ui";
// TYPES
import {
	OrderDetails,
	GuardrailModalProps,
	BaseModalProps,
	OptionTypeInitial,
	FormValues,
} from "../types";

import AdvancedInput from "../../../../components/layout/AdvancedInput";

const modalVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1 },
	exit: { opacity: 0 },
};

const QuickActionModal = <T,>({
	onClose,
	isOpen,
	activeUuid = "",
	modalSelected,
	setRefetchData,
	refetchData,
	updateRowData,
	setDeactivatedQuickMenuIds,
	handleBigModalClose,
	onUpdate,
}: BaseModalProps<T>) => {
	const { isAdmin } = usePrivilegeCheck();
	const { activeRowUuid } = useItemsContext();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [orderDetails, setOrderDetails] = useState<
		OrderDetails | null | undefined
	>();
	const [error, setError] = useState<string | null>(null);
	const [isGuardrailModalOpen, setIsGuardrailModalOpen] =
		useState<boolean>(false);
	// const [leasePrices, setLeasePrices] = useState<
	// 	Record<string, any> | null | undefined
	// >();
	const [isEditManager, setIsEditManager] = useState(false);
	const [isEditAdmin, setIsEditAdmin] = useState(false);
	const [triggerFetch, setTriggerFetch] = useState<boolean>(false);
	const [adminsAndManagers, setAdminsAndManagers] = useState<any[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1); // Track offset for lazy loading
	const [data, setData] = useState<any[]>([]); // State to store the list of admins and managers
	const [isFetchingMore, setIsFetchingMore] = useState(false);
	const [hasMorePages, setHasMorePages] = useState(true);
	const { addToaster } = useToaster();
	const userBadgeClassNames =
		"bg-gray-100 rounded-full px-2 p-1 text-sm text-gray-700 mr-2";

	// Fetch data when modal opens
	useEffect(() => {
		const fetchOrderDetails = async () => {
			if (isOpen && activeUuid) {
				// setIsLoading(true);
				try {
					const salesOrderItems = await getSalesOrderItems(
						activeUuid
					);
					const salesOrderDetails = await getSalesOrderDetails(
						activeUuid
					);


					// Initial fetch of admins and managers
					const initialAdminsAndManagers = await getAdminsAndManagers(
						100,
						1
					); // Fetch first 100 users from page 1
					setAdminsAndManagers(initialAdminsAndManagers);
					// Set the initial page after fetching the first 100
					setCurrentPage(1);
 					const admins = await getAdmins();
					setOrderDetails({
						...salesOrderDetails,
						admins,
					});

					// if (salesOrderItems) {
					// 	const leasePriceItem = checkLeaseTotals(
					// 		salesOrderItems,
					// 		salesOrderDetails
					// 	);
					// 	setLeasePrices(leasePriceItem);
					// }

					setError(null);
				} catch (err) {
					console.log(err);
					setError("An error occurred while fetching data");
				} finally {
					setIsLoading(false);
				}
			}
		};

		fetchOrderDetails();
	}, [isOpen, activeUuid, triggerFetch]);

	const orderNumber = orderDetails?.number || "—";
	const total = formatCurrency(orderDetails?._totalPurchase || "0");
	const orderTotalLease = formatCurrency(orderDetails?._totalLease || "0");

	const salesOrderNotes =
		orderDetails?.salesOrderNotes?.slice().reverse() || [];
	const approvalManagerName = orderDetails?.approvalUser?._name;
	const approvalManagerEmail = orderDetails?.approvalUser?.email;

	const approvalManagerUuid = orderDetails?.approvalUser?.uuid;
	const approvalManager = {
		name: approvalManagerName,
		uuid: approvalManagerUuid,
	};

	const approvalAdminName = orderDetails?.c_adminApproval?._name;
	const approvalAdminEmail = orderDetails?.c_adminApproval?.email;
	const approvalAdminUuid = orderDetails?.c_adminApproval?.uuid;
	const approvalAdmin = {
		name: approvalAdminName,
		uuid: approvalAdminUuid,
	};
	const assignedManager = orderDetails?.approvalUser?._name || "";

	const customerName =
		orderDetails?.contact?.firstName +
		" " +
		orderDetails?.contact?.lastName;
	const submittedByUsers = orderDetails?.createdByUser?._name || "—";
	let salesOrderStage = orderDetails?.salesOrderStage?.name;
	const updatedTime = formatDateMontDayYearTime(
		orderDetails?._updated?.timestamp
	);
	const dateOrder = formatDate(orderDetails?.dateOrder) || "—";
	const costCenter = orderDetails?.c_erpEntityId || "—";
	const salesOrderUuid = orderDetails?.uuid;
	const userData = localStorage.getItem("user");
	const userUuid = userData ? JSON.parse(userData).uuid : null;
	const _status = orderDetails?._status;
	const reasonForRequest = salesOrderNotes
		?.filter(
			(note: any) => note?.salesOrderNoteType.name === "Request Reason"
		)
		.map((note: { note: string }) => note.note);

	// Form setup
	const methods = useForm<FormValues>({
		mode: "onChange",
		defaultValues: {
			reason: "",
			approvalUser: {
				name: approvalManagerName || "",
				uuid: approvalManagerUuid || "",
			},
			c_adminApproval: {
				name: approvalAdminName || "",
				uuid: approvalAdminUuid || "",
			},
		},
	});

	const { handleSubmit, formState, watch, reset } = methods;

	const { isValid } = formState;
	const reasonValue = watch("reason");

	// Rename _name to name for options
	const renameNameProperty = (list: OptionTypeInitial[]) => {
		return list?.map((item) => {
			const { _name, email, uuid, ...rest } = item;
			return {
				name: `${_name} (${email})`,
				email: email,
				uuid: uuid,
				...rest,
			};
		});
	};
	const handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			e.preventDefault();
		}
	};

	// New lists with corrected property names
	const adminList = renameNameProperty(orderDetails?.admins || []);
	const adminsAndManagerList = renameNameProperty(adminsAndManagers || []);
	const [selectedAdminManager, setSelectedAdminManager] = useState<any>(null);
	const handleAdminManagerSelect = (user: any) => {
		setSelectedAdminManager(user);
	};

	const stripEmailFromName = (nameWithEmail: string) => {
		if (!nameWithEmail) return "";
		return nameWithEmail.replace(/\s*\(.*\)/, "");
	};

	const handleStageSubmit = (stage: Record<string, any>) => {
		handleSubmit(async (formData) => {
			setIsLoading(true);
			let response;
			let approverField: "approvalUser" | "c_adminApproval" | undefined;
			let approverUuid: string | undefined;
			let approverName: string | undefined;

			// Handle both AdvancedInput and BaseInput selections
			if (stage.valueKey === "approvalUser") {
				approverField = "approvalUser";
				if (selectedAdminManager) {
					approverUuid = selectedAdminManager.uuid;
					approverName =
						selectedAdminManager.name || selectedAdminManager.value;
				} else {
					approverUuid = formData.approvalUser.uuid;
					approverName =
						adminList.find((admin) => admin.uuid === approverUuid)
							?.name || "";
				}
			} else if (stage.valueKey === "c_adminApproval") {
				approverField = "c_adminApproval";
				if (selectedAdminManager) {
					approverUuid = selectedAdminManager.uuid;
					approverName =
						selectedAdminManager.name || selectedAdminManager.value;
				} else {
					approverUuid = formData.c_adminApproval.uuid;
					approverName =
						adminList.find((admin) => admin.uuid === approverUuid)
							?.name || "";
				}
			}

			if (approverField && approverUuid && approverName) {
				response = await updateUserAssignment(
					approverField,
					approverUuid,
					stage.id
				);

				if (response && response.status === 200) {
					setTriggerFetch((prevState) => !prevState);

					if (onUpdate) {
						onUpdate();
					}

					addToaster({
						isOpen: true,
						message: "Order updated successfully",
						status: "success",
					});

					if (approverField === "approvalUser") {
						updateRowData &&
							updateRowData(
								activeRowUuid || "defaultUuid",
								"approvalUser",
								approverName || "—"
							);
					} else if (approverField === "c_adminApproval") {
						const strippedApproverName =
							stripEmailFromName(approverName);
						updateRowData &&
							updateRowData(
								activeRowUuid || "defaultUuid",
								"assignedTo",
								strippedApproverName || "—"
							);
					}

					setSelectedAdminManager(null);
				} else {
					addToaster({
						isOpen: true,
						message: "An error occurred while updating the order",
						status: "error",
					});
				}

				stage.handleEditToggle();
				setIsLoading(false);
			} else {
				console.error("Approver UUID or Name is missing!");
				addToaster({
					isOpen: true,
					message: "Approver UUID or Name is missing",
					status: "error",
				});
				setIsLoading(false);
			}
		})();
	};

	const onSubmit = async (formData: Record<string, any>) => {
		setIsLoading(true);
		const { reason } = formData;
		let response;
		// need for real time update in status bubble on table
		const originalSalesOrderStage = salesOrderStage;

		if (modalSelected === "approve") {
			if (salesOrderStage === "Pending Initial Approval") {
				salesOrderStage = "Pending Approval";
			} else {
				salesOrderStage = "Pending Fulfillment";
			}
			response = await updateSalesOrder(
				salesOrderUuid as string,
				salesOrderStage,
				userUuid,
				false,
				reason,
				isAdmin
			);
		} else if (modalSelected === "deny") {
			// NOTE: MUST BE SPELLED EXACTLY CANCELED FOR THE API TO WORK
			response = await denySalesOrder(
				salesOrderUuid as string,
				"Canceled",
				userUuid,
				reason,
				isAdmin
			);
		} else if (modalSelected === "adminOnly") {
			salesOrderStage = "Pending Fulfillment";
			response = await updateSalesOrder(
				salesOrderUuid as string,
				salesOrderStage,
				userUuid,
				true,
				reason,
				isAdmin
			);
			handleBigModalClose && handleBigModalClose();
		} else {
			salesOrderStage = "Pending Fulfillment";
			response = await updateSalesOrder(
				salesOrderUuid as string,
				salesOrderStage,
				userUuid,
				false,
				formData.reason,
				isAdmin
			);
		}

		const toasterText =
			modalSelected === "approve"
				? "Order approved successfully"
				: modalSelected === "deny"
				? "Order denied successfully"
				: "Order approved successfully with manager override";

		if (response && response.status === 200) {
			addToaster({
				isOpen: true,
				message: `${toasterText}`,
				status: "success",
			});

			if (modalSelected === "approve" && updateRowData && activeRowUuid) {
				if (originalSalesOrderStage === "Pending Initial Approval") {
					updateRowData(activeRowUuid, "status", "Pending Approval");
					handleBigModalClose && handleBigModalClose();
				} else {
					updateRowData(
						activeRowUuid,
						"status",
						"pendingFulfillment"
					);
					handleBigModalClose && handleBigModalClose();
				}
			} else if (
				modalSelected === "deny" &&
				updateRowData &&
				activeRowUuid
			) {
				updateRowData(activeRowUuid, "status", "canceled");
			} else if (
				modalSelected === "adminOnly" &&
				updateRowData &&
				activeRowUuid
			) {
				updateRowData(activeRowUuid, "status", "pendingFulfillment");
			} else {
				updateRowData &&
					updateRowData(
						activeRowUuid || "defaultUuid",
						"assignedTo",
						"TEST NAME"
					);
			}
			setDeactivatedQuickMenuIds &&
				setDeactivatedQuickMenuIds((prevIds: string[]) => {
					if (!prevIds.includes(activeUuid)) {
						return [...prevIds, activeUuid];
					}
					return prevIds;
				});
		} else {
			addToaster({
				isOpen: true,
				message: "An error occurred",
				status: "error",
			});
		}

		if (setRefetchData) {
			setRefetchData(!refetchData);
		}

		setIsLoading(false);
		onClose();

		if (!isAdmin && handleBigModalClose && modalSelected === "approve") {
			handleBigModalClose();
		} else if (handleBigModalClose && modalSelected === "deny") {
			handleBigModalClose();
		}
	};

	const handleBackgroundClick = () => {
		if (isGuardrailModalOpen) {
			return;
		}

		if (!reasonValue || reasonValue.trim().length === 0) {
			onClose();
			return;
		}

		setIsGuardrailModalOpen(true);
	};

	const handleApproveAllStages = () => {
		setIsGuardrailModalOpen(true);
	};

	const handleModalClick = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.stopPropagation();
	};

	let iconColor = "";
	let modalTitle = "";
	let buttonText = "";
	let icon = "";
	let weight = "";
	let isRequired = false;
	let guardRailTitle = "Are you sure you want to approve all stages";
	let guardRailButtonText = "";

	switch (modalSelected) {
		case "approve":
			iconColor = "mint-700";
			modalTitle = "Reason for Approval";
			buttonText = "Approve Order";
			icon = "check";
			weight = "regular";
			guardRailTitle = "Do you want to approve this order?";
			guardRailButtonText = "Approve";
			break;
		case "adminOnly":
			iconColor = "mint-700";
			modalTitle = "Confirm Manager Override";
			buttonText = "Approve Order (Override)";
			icon = "badgeCheck";
			weight = "solid";
			guardRailTitle = "Do you want to approve this order?";
			guardRailButtonText = "Approve";
			break;
		case "deny":
			iconColor = "crimson-700";
			modalTitle = "Reason for Denial";
			buttonText = "Deny Order";
			icon = "xmark";
			weight = "regular";
			isRequired = true;
			guardRailTitle = "Do you want to deny this order?";
			guardRailButtonText = "Deny";
			break;
		case "assign":
			iconColor = "primary";
			modalTitle = "Assign & Manage";
			buttonText = "";
			icon = "userCheck";
			weight = "solid";
			guardRailTitle = "";
			guardRailButtonText = "";
			break;
		default:
			iconColor = "primary";
			modalTitle = "Action Confirmation";
			buttonText = "Confirm";
			icon = "check";
			weight = "regular";
			break;
	}

	const placeholderText =
		modalSelected === "approve"
			? "Please provide the reason for the approval"
			: modalSelected === "deny"
			? "Please provide the reason for the denial"
			: `Please provide the reason for manager override`;

	const textAreaLabel =
		modalSelected === "approve"
			? "Reason for Approval"
			: modalSelected === "deny"
			? "Reason for Denial"
			: "Reason for Approval";

	const textAreaField = {
		id: "reason",
		label: `${textAreaLabel}`,
		valueKey: "reason",
		inputType: "textArea",
		characterLimit: 255,
		placeholder: `${placeholderText}`,
		isRequired: isRequired,
		showRequiredIndicator: true,
		inputContaineWidth: "w-full h-full",
		labelTextClasses: "font-omnes-medium text-navy-500 text-sm mb-1",
		inputClasses: "rounded-md",
		textAreaHeight: "large",
		specificInputContainerClasses: "flex-col",
	};

	const handleGuardrailSave = () => {
		handleSubmit(onSubmit)();
		setIsGuardrailModalOpen(false);
		// onClose();
	};

	const stagesIcons = { icon: "stamp", weight: "regular" };

	const handleEditManagerToggle = () => {
		setIsEditManager(!isEditManager);
	};

	const handleEditAdminToggle = () => {
		setIsEditAdmin(!isEditAdmin);
	};

	// Timing issues with default form values loading, resetting form default values if details are loaded
	useEffect(() => {
		if (approvalManagerUuid || approvalAdminUuid) {
			reset({
				reason: "",
				approvalUser: approvalManager,
				c_adminApproval: approvalAdmin,
			});
		}
	}, [approvalManagerUuid, approvalAdminUuid, reset]);

	const stages = [
		{
			fontAwesomeIcon: stagesIcons,
			id: salesOrderUuid,
			key: `${salesOrderUuid}-manager`,
			title: "Approval: Manager Approval",
			adminsAndManagers: adminsAndManagerList || [],
			badges: ["Managers", "Admins"],
			isEdit: isEditManager,
			handleEditToggle: handleEditManagerToggle,
			approvalEmail: approvalManagerEmail,
			approvalUser: approvalManager.name,
			options: adminsAndManagerList,
			hasInput: true,
			label: "Assigned To",
			valueKey: "approvalUser",
			saveUuid: true,
		},
		{
			fontAwesomeIcon: stagesIcons,
			id: salesOrderUuid,
			key: `${salesOrderUuid}-admin`,
			title: "Approval: Admin Approval",
			badges: ["Admins"],
			isEdit: isEditAdmin,
			handleEditToggle: handleEditAdminToggle,
			approvalEmail: approvalAdminEmail,
			approvalUser: approvalAdmin.name,
			options: adminList,
			label: "Assigned To",
			hasInput: true,
			valueKey: "c_adminApproval",
			timeStampForChanges: updatedTime,
		},
	];

	const GuardrailModal = ({ title, message }: GuardrailModalProps) => (
		<motion.div
			className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
			variants={modalVariants}
			initial="hidden"
			animate="visible"
			exit="hidden"
			onClick={() => setIsGuardrailModalOpen(false)}
		>
			<div
				className="bg-white text-left min-w-[490px] relative rounded-lg p-5"
				onClick={(e) => e.stopPropagation()}
			>
				<p className="font-omnes-medium">{title}</p>
				<p className="mt-4">{message}</p>
				<div className="flex justify-end items-center mt-6">
					<div className="flex justify-end">
						<BaseButton
							type="button"
							text="Cancel"
							size="sm"
							customHeight="h-26px"
							fontColor="primary"
							shape="rounded"
							additionalClasses="hover:bg-primaryVeryLight border-primary text-nowrap px-4"
							onClick={() => setIsGuardrailModalOpen(false)}
						/>
						<BaseButton
							text={"Approve All Stages"}
							color={"primary"}
							fontColor={"white"}
							customHeight="h-26px"
							borderColor="primary"
							additionalClasses="hover:bg-primaryDefaultHover text-nowrap ml-3 px-4"
							size="sm"
							shape="rounded"
							onClick={handleGuardrailSave}
						/>
					</div>
				</div>
			</div>
		</motion.div>
	);

	const [currentSearchQuery, setCurrentSearchQuery] = useState("");
	interface FetchParams {
		searchQuery?: string;
		limit?: number;
		pageNumber?: number;
	}
	const [fetchMoreDataLoading, setFetchMoreDataLoading] =
		useState<boolean>(false);

	// Update fetchMoreAdminsAndManagers to accept an object
	const fetchMoreAdminsAndManagers = async ({
		limit = 100,
		pageNumber = 1,
	}: FetchParams): Promise<void> => {
		if (isFetchingMore || !hasMorePages) return;

		setIsFetchingMore(true);
		try {
			const moreAdminsAndManagers = await getAdminsAndManagers(
				limit,
				pageNumber
			);

			if (moreAdminsAndManagers.length === 0) {
				setHasMorePages(false);
			} else {
				setData((prevData) => [...prevData, ...moreAdminsAndManagers]);
				setCurrentPage(pageNumber + 1);
			}
		} catch (error) {
			console.error("Error loading admins and managers", error);
		} finally {
			setIsFetchingMore(false);
		}
	};
	const handleSearch = useCallback(
		(searchQuery: string): void => {
			setCurrentSearchQuery(searchQuery);
			setCurrentPage(1);
			setData([]);
			setHasMorePages(true);

			fetchMoreAdminsAndManagers({ searchQuery, pageNumber: 1 });
		},
		[fetchMoreAdminsAndManagers]
	);

	const fetchMoreData = useCallback(async (): Promise<void> => {
		await fetchMoreAdminsAndManagers({
			pageNumber: currentPage,
		});
	}, [currentPage, fetchMoreAdminsAndManagers]);

	const findSpecificValue = useCallback(
		async (inputValue: string): Promise<any> => {
			setIsFetchingMore(true);
			const result = await getSearchValuesFromAdvancedInput(
				1000,
				1,
				inputValue
			);
			setIsFetchingMore(false);
			return result;
		},
		[]
	);
	const handleClearInput = (): void => {
		setCurrentSearchQuery("");
		setCurrentPage(1);
		setData([]);
		setHasMorePages(true);

		fetchMoreAdminsAndManagers({ searchQuery: "", pageNumber: 1 });
	};

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				onKeyDown={
					modalSelected === "assign" ? handleKeyDown : undefined
				}
			>
				{isOpen && (
					<motion.div
						className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-50"
						variants={modalVariants}
						initial="hidden"
						animate="visible"
						onClick={handleBackgroundClick}
					>
						<div
							className="bg-white min-w-[600px] max-w-[600px]  relative rounded-2xl max-sm:min-w-[400px]"
							onClick={handleModalClick}
						>
							{isLoading ? (
								<div className="flex items-center justify-center w-full h-[540px] min-h-full ">
									<LoadingMessage message="" />
								</div>
							) : error ? (
								<div className="flex items-center justify-center">
									<p className="text-red-500">{error}</p>
								</div>
							) : !orderDetails ? (
								<div className="flex items-center justify-center w-full h-[540px] min-h-full">
									<LoadingMessage message="" />
								</div>
							) : (
								<div className="flex flex-col justify-center p-5 w-full gap-5">
									<div className="flex">
										<div
											className={`text-${iconColor} text-xl mr-2`}
										>
											{getFontAwesomeIcon(
												icon,
												weight as
													| "regular"
													| "solid"
													| "light"
													| undefined
											)}
										</div>
										<div className="text-navy-500 font-omnes-medium text-xl flex w-full mr-5">
											{modalTitle}
											{modalTitle ===
												"Confirm Manager Override" && (
												<div className="flex items-center">
													<BaseToolTip
														message={
															"Approving will skip all other approval steps"
														}
														icon="circleInfo"
														toolTipPlacement="right"
													/>
												</div>
											)}
										</div>
										<div
											className="text-navy-300 hover:cursor-pointer hover:text-gray-800 text-xl"
											onClick={handleBackgroundClick}
										>
											{getFontAwesomeIcon(
												"xmark",
												"regular"
											)}
										</div>
									</div>
									<div className="flex flex-col gap-2">
										<div className="flex gap-2">
											<BaseText
												text={`	Assigned Manager:`}
												size="text-sm"
												color="text-navy-500"
												fontFamily="font-omnes-medium"
												tag="p"
											/>
											<span>
												<BaseText
													text={
														assignedManager || "—"
													}
													size="text-sm"
													color="text-navy-500"
													fontFamily="font-omnes-regular"
													tag="p"
												/>
											</span>
										</div>
										<div className="flex gap-5 justify-center align-center flex-col">
											<div className="flex gap-2">
												<BaseText
													text={`Order for:`}
													size="text-sm"
													color="text-navy-500"
													fontFamily="font-omnes-medium"
													tag="p"
												/>
												<span className="pr-2">
													<BaseText
														text={customerName}
														size="text-sm"
														color="text-navy-500"
														fontFamily="font-omnes-regular"
														tag="p"
													/>
												</span>
												<div className="flex gap-2">
													<BaseText
														text={`Submitted by:`}
														size="text-sm"
														color="text-navy-500"
														fontFamily="font-omnes-medium"
														tag="p"
													/>
													<span>
														<BaseText
															text={
																submittedByUsers
															}
															size="text-sm"
															color="text-navy-500"
															fontFamily="font-omnes-regular"
															tag="p"
														/>
													</span>
												</div>
											</div>
										</div>
										<div className="flex gap-2 flex-col">
											<div className="flex gap-2">
												<BaseText
													text={`Order #:`}
													size="text-sm"
													color="text-navy-500"
													fontFamily="font-omnes-medium"
													tag="p"
												/>
												<span className="pr-2">
													<BaseText
														text={orderNumber}
														size="text-sm"
														color="text-navy-500"
														fontFamily="font-omnes-regular"
														tag="p"
													/>
												</span>
												<div className="flex gap-2">
													<BaseText
														text={`Order Date:`}
														size="text-sm"
														color="text-navy-500"
														fontFamily="font-omnes-medium"
														tag="p"
													/>
													<span className="pr-2">
														<BaseText
															text={dateOrder}
															size="text-sm"
															color="text-navy-500"
															fontFamily="font-omnes-regular"
															tag="p"
														/>
													</span>
												</div>
												<div className="flex gap-2">
													<div
														className={`gap-2 flex`}
													>
														<BaseText
															text={`Total:`}
															size="text-sm"
															color="text-navy-500"
															fontFamily="font-omnes-medium"
															tag="p"
														/>
														<div
															className={`${
																orderTotalLease &&
																orderTotalLease !==
																	"0"
																	? "flex-col"
																	: "flex"
															}`}
														>
															<BaseText
																text={`$ ${total}${
																	orderTotalLease &&
																	orderTotalLease !==
																		"0"
																		? " +"
																		: ""
																}`}
																size="text-sm"
																color="text-navy-500 text-start"
																fontFamily="font-omnes-regular"
																tag="p"
															/>
															{orderTotalLease &&
																orderTotalLease !==
																	"0" && (
																	<div className="flex flex-row gap-2 justify-end">
																		<BaseText
																			text={`$${orderTotalLease}/mo`}
																			size="text-sm"
																			color="text-navy-500 text-start"
																			fontFamily="font-omnes-regular"
																			tag="p"
																		/>
																	</div>
																)}
														</div>
													</div>
												</div>
											</div>

											<div className="flex gap-2">
												<BaseText
													text={`Cost Center:`}
													size="text-sm"
													color="text-navy-500"
													fontFamily="font-omnes-medium"
													tag="p"
												/>
												<span>
													<BaseText
														text={costCenter}
														size="text-sm"
														color="text-navy-500"
														fontFamily="font-omnes-regular"
														tag="p"
													/>
												</span>
											</div>
											<div className="font-omnes-medium grid grid-cols-8 text-left h-auto mb-5">
												<div className="col-span-2">
													<BaseText
														text={`Reason for Request:`}
														size="text-sm"
														color="text-navy-500"
														fontFamily="font-omnes-medium"
														tag="p"
													/>
												</div>
												<div className="flex-col col-span-6">
													{reasonForRequest.length >
													0 ? (
														reasonForRequest.map(
															(
																note: any,
																index: number
															) => (
																<BaseText
																	key={index}
																	text={
																		note ||
																		"—"
																	}
																	size="text-sm"
																	color="text-navy-500 text-start"
																	fontFamily="font-omnes-regular"
																	tag="p"
																/>
															)
														)
													) : (
														<BaseText
															text="—"
															size="text-sm"
															color="text-navy-500 text-start"
															fontFamily="font-omnes-regular"
															tag="p"
														/>
													)}
												</div>
											</div>
										</div>
										<div className="border-b border-navy-200 w-full"></div>
										<div className="w-full h-full flex flex-col">
											{modalSelected !== "assign" ? (
												<BaseInput
													field={textAreaField}
												/>
											) : (
												<div className=" ">
													<div className="text-left flex pl-3">
														<div className="text-sm font-omnes-medium">
															Stages
														</div>
														{isAdmin &&
															_status ===
																"pendingInitialApproval" && (
																<div
																	onClick={
																		handleApproveAllStages
																	}
																	className="pl-2.5 text-primary cursor-pointer text-sm font-omnes-medium"
																>
																	Approve All
																	Stages
																</div>
															)}
													</div>
													<div className="relative">
														{/* Line connecting the checkmarks */}
														<div
															className={`absolute top-7 left-[19px] w-0.5 ${
																_status ===
																"pendingInitialApproval"
																	? "bg-gray-300"
																	: "bg-primary"
															}`}
															style={{
																height: "calc(100% - 11rem)",
																zIndex: "0",
															}}
														></div>

														{/* Loop through the stages */}
														{stages.map(
															(stage, index) => (
																<div
																	key={
																		stage.key
																	}
																	className="grid grid-cols-12 relative"
																>
																	{/* Checkmark */}
																	<div className="size-10 col-span-1 flex justify-center items-center relative">
																		<div
																			className={`${
																				_status ===
																					"pendingInitialApproval" ||
																				index ===
																					0
																					? "bg-white"
																					: "bg-primary"
																			} ${
																				_status ===
																				"pendingInitialApproval"
																					? "border-gray-300"
																					: "border-primary"
																			} border-2 size-5 flex flex-col justify-center items-center rounded-full relative z-10`}
																		>
																			{/* Only render the icon if _status is not "pendingInitialApproval" and index !== 0 */}
																			{_status !==
																				"pendingInitialApproval" &&
																				index !==
																					0 && (
																					<span className="absolute text-white text-xs top-0.5">
																						{getFontAwesomeIcon(
																							"check",
																							"solid"
																						)}
																					</span>
																				)}
																		</div>
																	</div>
																	{/* Input */}
																	<div className="col-span-11 min-h-40 text-left text-sm font-omnes-medium">
																		<div className="pb-3 pt-2">
																			<span>
																				{getFontAwesomeIcon(
																					stage
																						.fontAwesomeIcon
																						.icon,
																					stage
																						.fontAwesomeIcon
																						.weight as
																						| "regular"
																						| "solid"
																						| "light"
																						| undefined
																				)}
																			</span>
																			<span className="pl-2">
																				{
																					stage.title
																				}
																			</span>
																		</div>
																		<span className="text-sm">
																			{_status ===
																			"pendingApproval" ? (
																				index ===
																					0 && (
																					<>
																						Allowed
																						Users
																					</>
																				)
																			) : (
																				<>
																					Allowed
																					Users
																				</>
																			)}
																		</span>
																		<div className="py-1">
																			{(_status !==
																				"pendingApproval" ||
																				index ===
																					0) &&
																			stage.badges &&
																			stage
																				.badges
																				.length >
																				0
																				? stage.badges.map(
																						(
																							badge,
																							idx
																						) => (
																							<span
																								className={`${userBadgeClassNames}`}
																								key={
																									idx
																								}
																							>
																								{
																									badge
																								}
																							</span>
																						)
																				  )
																				: null}
																		</div>
																		<>
																			{_status ===
																				"pendingApproval" &&
																			!stage.isEdit ? (
																				<>
																					{index ===
																						0 && (
																						<>
																							<div className="pb-3 pt-1  text-sm text-omnes-medium">
																								<span>
																									Assigned
																									To
																								</span>
																								<span
																									className="text-primary cursor-pointer pl-3"
																									onClick={
																										stage.handleEditToggle
																									}
																								>
																									Edit
																								</span>
																							</div>
																						</>
																					)}
																				</>
																			) : (
																				<>
																					{!stage.isEdit && (
																						<div className="pb-3 text-sm font-omnes-medium py-1">
																							<span>
																								Assigned
																								To
																							</span>

																							<span
																								className="text-primary cursor-pointer pl-3"
																								onClick={
																									stage.handleEditToggle
																								}
																							>
																								Edit
																							</span>
																						</div>
																					)}
																				</>
																			)}

																			{!stage.isEdit && (
																				<div className="flex items-center min-h-10">
																					<span
																						className={`${userBadgeClassNames}`}
																					>
																						{stage.approvalUser
																							? `${
																									stage.approvalUser
																							  } ${`(${stage.approvalEmail})`} ${
																									index ===
																									0
																										? "(Manager)"
																										: "(Admin)"
																							  }`
																							: "—"}
																					</span>
																				</div>
																			)}
																			{stage.isEdit &&
																				stage.hasInput && (
																					<div className="relative">
																						<div className="absolute top-3 right-1 text-sm">
																							<span
																								onClick={
																									stage.handleEditToggle
																								}
																								className="pr-4 hover:cursor-pointer"
																							>
																								<BaseText
																									text={`Cancel`}
																									size="text-sm"
																									color="text-primary"
																									fontFamily="font-omnes-medium"
																									tag="span"
																								/>
																							</span>

																							<span
																								onClick={() =>
																									handleStageSubmit(
																										stage
																									)
																								}
																								className="hover:cursor-pointer"
																							>
																								<BaseText
																									text={`Save`}
																									size="text-sm"
																									color="text-primary"
																									fontFamily="font-omnes-medium"
																									tag="span"
																								/>
																							</span>
																						</div>
																						<div className="pt-3 min-h-10">
																							{stage
																								.options
																								.length <
																							100 ? (
																								<BaseInput
																									labelTextColor="text-gray-900"
																									field={{
																										label: stage.label,
																										labelTextClasses:
																											"",
																										id: stage.id as string,
																										valueKey:
																											stage.valueKey,
																										valueTextClasses:
																											"font-omnes-medium",
																										inputType:
																											"select",
																										inputContainerWidth:
																											"w-full",
																										placeholder:
																											"e.g. “John Doe”",
																										inputWidth:
																											"w-full",
																										inputGridClasses:
																											"",
																										isSearchable:
																											true,
																										specificInputContainerClasses:
																											"flex-col",
																										saveUuid:
																											stage.saveUuid,
																									}}
																									inputContainerClasses={
																										""
																									}
																									options={
																										stage.options
																									}
																									isRequired={
																										false
																									}
																									menuPlacement={
																										"bottom"
																									}
																								/>
																							) : (
																								<div className="w-full">
																									<AdvancedInput
																										label="Assigned To"
																										onSelect={
																											handleAdminManagerSelect
																										}
																										fetchMoreData={
																											fetchMoreData
																										}
																										findSpecificValue={
																											findSpecificValue
																										}
																										data={
																											data
																										}
																										isFetchingMore={
																											isFetchingMore
																										}
																										hasMorePages={
																											hasMorePages
																										}
																										onClearInput={
																											handleClearInput
																										}
																									/>
																								</div>
																							)}
																						</div>
																					</div>
																				)}
																		</>
																		{_status ===
																			"pendingApproval" && (
																			<div className="text-gray-500 text-sm">
																				{
																					stage.timeStampForChanges
																				}
																			</div>
																		)}
																	</div>
																</div>
															)
														)}
													</div>
												</div>
											)}
										</div>
										<div className="flex justify-end">
											{modalSelected !== "assign" && (
												<>
													<BaseButton
														type="button"
														text="Cancel"
														size="sm"
														customHeight="h-26px"
														fontColor="primary"
														shape="rounded"
														additionalClasses="border-primary text-nowrap max-sm:size-8 max-sm:justify-center text-14px px-8"
														onClick={onClose}
														hoverBackground="primaryHoverLight"
													/>
													{modalSelected ===
														"approve" &&
													!assignedManager ? (
														<BaseToolTip
															message="Needs an Assigned Manager"
															toolTipPlacement="top"
															children={
																<BaseButton
																	text={
																		buttonText
																	}
																	color={
																		"primary"
																	}
																	fontColor={
																		"white"
																	}
																	hoverBackground="primaryDefaultHover"
																	customHeight="h-26px"
																	borderColor="primary"
																	additionalClasses="text-nowrap ml-5 max-sm:size-8 max-sm:justify-center text-14px px-2"
																	size="sm"
																	shape="rounded"
																	icon={getFontAwesomeIcon(
																		icon,
																		weight as
																			| "regular"
																			| "solid"
																			| "light"
																			| undefined
																	)}
																	iconBreakpoint="max-sm:pr-0"
																	as="button"
																	hasTextBreakpoint={
																		true
																	}
																	textBreakpoint="max-sm:hidden"
																	fontFamily="omnes-medium"
																	iconPadding="pr-2"
																	onClick={handleSubmit(
																		onSubmit
																	)}
																	isDisabled={
																		true
																	}
																/>
															}
														/>
													) : (
														<BaseButton
															text={buttonText}
															color={"primary"}
															fontColor={"white"}
															hoverBackground=""
															customHeight="h-26px"
															borderColor="primary"
															additionalClasses="hover:bg-primaryDefaultHover text-nowrap ml-5 max-sm:size-8 max-sm:justify-center text-14px px-2"
															size="sm"
															shape="rounded"
															icon={getFontAwesomeIcon(
																icon,
																weight as
																	| "regular"
																	| "solid"
																	| "light"
																	| undefined
															)}
															iconBreakpoint="max-sm:pr-0"
															as="button"
															hasTextBreakpoint={
																true
															}
															textBreakpoint="max-sm:hidden"
															fontFamily="omnes-medium"
															iconPadding="pr-2"
															onClick={handleSubmit(
																onSubmit
															)}
															isDisabled={
																!isValid
															}
														/>
													)}
												</>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
					</motion.div>
				)}
				{isGuardrailModalOpen && (
					<GuardrailModal
						title={"Are you sure you want to approve all stages?"}
						message={`This will approve all stages and set you as the approver.`}
						confirmButtonText={"Approve All Stages"}
						onCancel={() => setIsGuardrailModalOpen(false)}
						onConfirm={handleGuardrailSave}
					/>
				)}
			</form>
		</FormProvider>
	);
};

export default QuickActionModal;
