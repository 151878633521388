import { Dispatch, SetStateAction } from "react";
import { useDarkMode, useUrl } from "../../hooks";

export type ColumnSortArray = string[];

interface SortArrowIconProps {
	direction: "up" | "down";
	columnName: string;
	setColumn: Dispatch<SetStateAction<{ [key: string]: string }>>;
	isActive: boolean;
	parentIndex?: number;
	slug: string;
	onClick?: () => void;
}

const SortArrowIcon = (props: SortArrowIconProps) => {
	const { direction, setColumn, isActive, parentIndex, slug, onClick } =
		props;
	const { darkMode } = useDarkMode();
	const { setParentIndex } = useUrl();

	const handleClick = () => {
		const directionValue = direction === "up" ? "+" : "-";
		setColumn({ [slug]: directionValue });
		setParentIndex && setParentIndex(parentIndex || 0);
		onClick && onClick();
	};

	let fillColor;
	if (isActive) {
		fillColor = "primary";
	} else {
		fillColor = darkMode ? "#a1bfe6" : "#000";
	}

	const arrowClass = isActive ? "active" : "";
	const fillClass = "fill-light-mode";
	const arrowContainerClassNames = `flex justify-center items-center pt-[1px] ${direction} ${arrowClass} `;
	const arrowSvgClassNames = `hover:fill-current text-primary dark:fill-current  ${fillClass}`;

	return (
		<div className={arrowContainerClassNames}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="8"
				height="6"
				viewBox="0 0 8 6"
				fill="none"
				className={arrowSvgClassNames}
				onClick={handleClick}
			>
				{direction === "up" ? (
					<>
						<path
							d="M3.47265 0.960938C3.7539 0.65625 4.24609 0.65625 4.52734 0.960938L7.52734 3.96094C7.73828 4.17188 7.80859 4.5 7.6914 4.78125C7.57421 5.0625 7.3164 5.25 7.01171 5.25H1.01171C0.707027 5.25 0.425777 5.0625 0.308589 4.78125C0.191402 4.5 0.261714 4.17188 0.472652 3.96094L3.47265 0.960938Z"
							fill="#535662"
						/>
					</>
				) : (
					<>
						<path
							d="M3.47265 5.03906L0.472652 2.03906C0.261714 1.82812 0.191402 1.5 0.308589 1.21875C0.425777 0.9375 0.707027 0.75 1.01171 0.75H7.01171C7.29296 0.75 7.57421 0.9375 7.6914 1.21875C7.80859 1.5 7.73828 1.82812 7.52734 2.03906L4.52734 5.03906C4.24609 5.34375 3.7539 5.34375 3.47265 5.03906Z"
							fill="#535662"
						/>
					</>
				)}
			</svg>
			<div
				className={`sort-arrow-icon-background ${
					direction === "up"
						? "sort-arrow-icon-background-up "
						: "sort-arrow-icon-background-down "
				}`}
			></div>
		</div>
	);
};

export default SortArrowIcon;
