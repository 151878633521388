// @ts-nocheck
//FIXME: fix the types in the api
import { all } from "axios";
import { OptionsType, togaApiRequest } from "../../../../api/toga";
import { Invoice, OrderDetails } from "../OrdersInterfaces";

export interface Field {
	[key: string]: string | string[] | Field;
}

export interface ExtendedOptionsType extends Omit<OptionsType, "fields"> {
	depth?: number;
	fields?: (
		| string
		| Field
		| { location: string[]; shipToAddress: string[] }
	)[];
	where?: any;
	join?: any;
	ojoin?: any;
	sort?: any;
	recordsPerPage?: number;
	page?: number;
	[key: string]: any;
}

interface GetOrdersOptions extends ExtendedOptionsType {}

export async function getOrderDetails(
	uuid: string
): Promise<OrderDetailsResult | undefined> {
	const storedHostName = localStorage.getItem("hostName");

	let output: {
		salesOrder: OrderDetails | null;
		items: any[];
		invoices: any[];
		shipments: any[];
		errorCode?: number;
		errorMessage?: string;
	} = {
		salesOrder: null,
		items: [],
		invoices: [],
		shipments: [],
	};

	/* ------------------------------------- Sales Order Header ------------------------------------- */
	try {
		let options: ExtendedOptionsType = {
			join: [
				{
					SalesOrderNotes: {
						"SalesOrderNotes.salesOrderId": "SalesOrders.id",
					},
				},
			],
		};
		let response = await togaApiRequest(
			"GET",
			"/sales-orders/" + uuid,
			null,
			options
		);

		if (!response.isSuccess) {
			if (response.status === 403) {
				output.errorCode = 403;
				output.errorMessage =
					response.error ||
					"Operation Failed: You are not authorized to perform this action.";
				return output;
			} else {
				output.errorCode = response.status || 500;
				output.errorMessage =
					response.error ||
					"An error occurred while fetching sales order.";
				return output;
			}
		}

		output.salesOrder = response.data.salesOrders;
	} catch (error: any) {
		console.error("Error fetching sales order header:", error.message);
		output.errorCode = error.response?.status || 500;
		output.errorMessage = error.message || "An unexpected error occurred.";
		return output;
	}

	/* -------------------------------------- Sales Order Items ------------------------------------- */
	try {
		let options: ExtendedOptionsType = {
			fields: [
				"uuid",
				"lineNumber",
				"Bundles.name",
				"Bundles.uuid",
				"BundleItems.uuid",
				"BundleItems.isRequired",
				"BundleItemGroups.name",
				"Items.partNumber",
				"Items.skuNumber",
				"Items.upcCode",
				"Items.description",
				"Manufacturers.name",
				"quantity",
				"_qtyFulfilled",
				"_qtyBilled",
				"_extPrice",
				"price",
				"PriceTypes.name",
			],
			ojoin: [
				{
					Items: {
						"Items.id": "SalesOrderItems.itemId",
					},
				},
				{
					Manufacturers: {
						"Manufacturers.id": "Items.manufacturerId",
					},
				},
				{
					BundleItems: {
						"BundleItems.id": "SalesOrderItems.bundleItemId",
					},
				},
				{
					Bundles: {
						"Bundles.id": "BundleItems.bundleId",
					},
				},
				{
					BundleItemGroups: {
						"BundleItemGroups.id": "BundleItems.bundleItemGroupId",
					},
				},
				{
					PriceTypes: {
						"PriceTypes.id": "SalesOrderItems.priceTypeId",
					},
				},
			],
		};
		let response = await togaApiRequest(
			"GET",
			"/sales-orders/" + uuid + "/sales-order-items",
			null,
			options
		);

		if (!response.isSuccess) {
			if (response.status === 403) {
				output.errorCode = 403;
				output.errorMessage =
					response.error ||
					"Operation Failed: You are not authorized to perform this action.";
				return output;
			} else {
				output.errorCode = response.status || 500;
				output.errorMessage =
					response.error ||
					"An error occurred while fetching sales order items.";
				return output;
			}
		}

		output.items = response.data.salesOrderItems;
	} catch (error: any) {
		console.error("Error fetching sales order items:", error.message);
		output.errorCode = error.response?.status || 500;
		output.errorMessage = error.message || "An unexpected error occurred.";
		return output;
	}

	/* ------------------------------------------ Shipments ----------------------------------------- */
	try {
		let options: ExtendedOptionsType = {
			join: [
				{
					ItemFulfillmentPackages: {
						"TrackingNumbers.id":
							"ItemFulfillmentPackages.trackingNumberId",
					},
				},
				{
					ItemFulfillments: {
						"ItemFulfillments.id":
							"ItemFulfillmentPackages.itemFulfillmentId",
					},
				},
				{
					SalesOrders: {
						"SalesOrders.id": "ItemFulfillments.salesOrderId",
					},
				},
			],
			where: {
				and: [{ "SalesOrders.uuid": { "=": uuid } }],
			},
			fields: [
				"uuid",
				"number",
				"status",
				{
					shippingCarrier: ["name", "urlLogo", "urlLinkPrefix"],
					shippingMethod: ["name"],
				},
			],
		};

		let response;
		if (storedHostName == "PRUDENTIAL") {
			const trackingResponse = await getTrackingNumber(uuid);

			if (
				output.salesOrder &&
				trackingResponse &&
				trackingResponse.length > 0
			) {
				output.salesOrder.trackingNumbers = [
					{
						shippingCarrierId: "1",
						shippingMethodId: null,
						number: trackingResponse[0].number,
						shippingCarrier: {
							name: "FedEx",
							urlLogo: "/carrierlogos/FedEx-logo.svg",
							urlLinkPrefix:
								"https://www.fedex.com/fedextrack/?trknbr=",
						},
						shippingMethod: {
							name: null,
						},
					},
				];
			}
		} else {
			// Getting tracking numbers of sales order
			response = await togaApiRequest(
				"GET",
				"/tracking-numbers",
				null,
				options
			);

			if (!response.isSuccess) {
				if (response.status === 403) {
					output.errorCode = 403;
					output.errorMessage =
						response.error ||
						"Operation Failed: You are not authorized to perform this action.";
					return output;
				} else {
					output.errorCode = response.status || 500;
					output.errorMessage =
						response.error ||
						"An error occurred while fetching tracking numbers.";
					return output;
				}
			}

			const salesOrderTrackingNumbers = response.data.trackingNumbers;

			// Get Invoice Tracking Numbers
			const optionsInvoiceTracking: ExtendedOptionsType = {
				join: [
					{
						InvoiceTrackingNumbers: {
							"InvoiceTrackingNumbers.trackingNumberId":
								"TrackingNumbers.id",
						},
					},
					{
						Invoices: {
							"Invoices.id": "InvoiceTrackingNumbers.invoiceId",
						},
					},
					{
						SalesOrders: {
							"SalesOrders.id": "Invoices.salesOrderId",
						},
					},
				],
				where: {
					and: [{ "SalesOrders.uuid": { "=": uuid } }],
				},
				fields: [
					"number",
					{
						shippingCarrier: ["name", "urlLogo", "urlLinkPrefix"],
						shippingMethod: ["name"],
					},
				],
			};

			// Getting tracking numbers from invoices
			const invoiceTrackingResponse = await togaApiRequest(
				"GET",
				"/tracking-numbers",
				null,
				optionsInvoiceTracking
			);

			if (!invoiceTrackingResponse.isSuccess) {
				if (invoiceTrackingResponse.status === 403) {
					output.errorCode = 403;
					output.errorMessage =
						invoiceTrackingResponse.error ||
						"Operation Failed: You are not authorized to perform this action.";
					return output;
				} else {
					output.errorCode = invoiceTrackingResponse.status || 500;
					output.errorMessage =
						invoiceTrackingResponse.error ||
						"An error occurred while fetching invoice tracking numbers.";
					return output;
				}
			}

			const invoiceTrackingNumbers =
				invoiceTrackingResponse.data.trackingNumbers;

			// Get Tracking Numbers from ASN
			const optionsASNTracking: ExtendedOptionsType = {
				join: [
					{
						AdvanceShippingNoticeUnits: {
							"AdvanceShippingNoticeUnits.trackingNumberId":
								"TrackingNumbers.id",
						},
					},
					{
						AdvanceShippingNoticeItems: {
							"AdvanceShippingNoticeItems.id":
								"AdvanceShippingNoticeUnits.advanceShippingNoticeItemId",
						},
					},
					{
						AdvanceShippingNotices: {
							"AdvanceShippingNotices.id":
								"AdvanceShippingNoticeItems.advanceShippingNoticeId",
						},
					},
					{
						PurchaseOrders: {
							"PurchaseOrders.id":
								"AdvanceShippingNotices.purchaseOrderId",
						},
					},
					{
						PurchaseOrderItems: {
							"PurchaseOrders.id":
								"PurchaseOrderItems.purchaseOrderId",
						},
					},
					{
						SalesOrderItems: {
							"SalesOrderItems.id":
								"PurchaseOrderItems.salesOrderItemId",
						},
					},
					{
						SalesOrders: {
							"SalesOrders.id": "SalesOrderItems.salesOrderId",
						},
					},
				],
				where: {
					and: [{ "SalesOrders.uuid": { "=": uuid } }],
				},
			};

			// Getting tracking numbers from ASN
			const asnTrackingResponse = await togaApiRequest(
				"GET",
				"/tracking-numbers",
				null,
				optionsASNTracking
			);

			if (!asnTrackingResponse.isSuccess) {
				if (asnTrackingResponse.status === 403) {
					output.errorCode = 403;
					output.errorMessage =
						asnTrackingResponse.error ||
						"Operation Failed: You are not authorized to perform this action.";
					return output;
				} else {
					output.errorCode = asnTrackingResponse.status || 500;
					output.errorMessage =
						asnTrackingResponse.error ||
						"An error occurred while fetching ASN tracking numbers.";
					return output;
				}
			}

			const asnTrackingNumbers = asnTrackingResponse.data.trackingNumbers;

			const combinedTrackingNumbers = [
				...salesOrderTrackingNumbers,
				...invoiceTrackingNumbers,
				...asnTrackingNumbers,
			];

			// Filter for unique tracking numbers
			const uniqueTrackingNumbers = combinedTrackingNumbers.reduce(
				(acc: any[], current: any) => {
					if (!acc.find((item) => item.number === current.number)) {
						acc.push(current);
					}
					return acc;
				},
				[]
			);

			output.shipments = uniqueTrackingNumbers;
		}
	} catch (error: any) {
		console.error("Error fetching shipments:", error.message);
		output.errorCode = error.response?.status || 500;
		output.errorMessage = error.message || "An unexpected error occurred.";
		return output;
	}

	/* ------------------------------------------ Invoices ------------------------------------------ */
	try {
		let options: ExtendedOptionsType = {
			fields: [
				"uuid",
				"number",
				"dateInvoice",
				"dateDue",
				"total",
				"amountDue",
				"PaymentTerms.name",
				"status",
			],
			join: [
				{
					SalesOrders: {
						"SalesOrders.id": "Invoices.salesOrderId",
					},
				},
			],
			ojoin: [
				{
					PaymentTerms: {
						"PaymentTerms.id": "Invoices.paymentTermsId",
					},
				},
			],
			where: {
				and: [{ "SalesOrders.uuid": { "=": uuid } }],
			},
		};
		let response = await togaApiRequest("GET", "/invoices", null, options);

		if (!response.isSuccess) {
			if (response.status === 403) {
				output.errorCode = 403;
				output.errorMessage =
					response.error ||
					"Operation Failed: You are not authorized to perform this action.";
				return output;
			} else {
				output.errorCode = response.status || 500;
				output.errorMessage =
					response.error ||
					"An error occurred while fetching invoices.";
				return output;
			}
		}

		output.invoices = response.data.invoices;
	} catch (error: any) {
		console.error("Error fetching invoices:", error.message);
		output.errorCode = error.response?.status || 500;
		output.errorMessage = error.message || "An unexpected error occurred.";
		return output;
	}

	/* ---------------------------------------- Invoice Items --------------------------------------- */
	try {
		let options: ExtendedOptionsType = {
			fields: [
				"SalesOrderItems.uuid",
				"Invoices.uuid",
				"Invoices.number",
			],
			join: [
				{
					SalesOrderItems: {
						"SalesOrderItems.id": "InvoiceItems.salesOrderItemId",
					},
				},
				{
					SalesOrders: {
						"SalesOrders.id": "SalesOrderItems.salesOrderId",
					},
				},
				{
					Invoices: {
						"Invoices.id": "InvoiceItems.invoiceId",
					},
				},
			],
			where: {
				and: [{ "SalesOrders.uuid": { "=": uuid } }],
			},
		};
		let response = await togaApiRequest(
			"GET",
			"/invoice-items",
			null,
			options
		);

		if (!response.isSuccess) {
			if (response.status === 403) {
				output.errorCode = 403;
				output.errorMessage =
					response.error ||
					"Operation Failed: You are not authorized to perform this action.";
				return output;
			} else {
				output.errorCode = response.status || 500;
				output.errorMessage =
					response.error ||
					"An error occurred while fetching invoice items.";
				return output;
			}
		}

		// Build an array of invoice UUIDs to assign for each sales order item UUID
		const invoiceUuids: { [key: string]: any[] } = {};
		for (const invoiceItem of response.data.invoiceItems) {
			const salesOrderItemUuid = invoiceItem.SalesOrderItems.uuid;
			if (!invoiceUuids[salesOrderItemUuid]) {
				invoiceUuids[salesOrderItemUuid] = [];
			}

			invoiceUuids[salesOrderItemUuid].push({
				uuid: invoiceItem.Invoices.uuid,
				number: invoiceItem.Invoices.number,
			});
		}

		// Bring these into the output.items array
		for (const item of output.items) {
			if (invoiceUuids[item.uuid]) {
				item.invoices = invoiceUuids[item.uuid];
			} else {
				item.invoices = [];
			}
		}
	} catch (error: any) {
		console.error("Error fetching invoice items:", error.message);
		output.errorCode = error.response?.status || 500;
		output.errorMessage = error.message || "An unexpected error occurred.";
		return output;
	}

	/* ----------------------------------- Item Fulfillment Items ----------------------------------- */
	try {
		let options: ExtendedOptionsType = {
			fields: [
				"SalesOrderItems.uuid",
				"TrackingNumbers.uuid",
				"TrackingNumbers.number",
			],
			join: [
				{
					SalesOrderItems: {
						"SalesOrderItems.id":
							"ItemFulfillmentItems.salesOrderItemId",
					},
				},
				{
					SalesOrders: {
						"SalesOrders.id": "SalesOrderItems.salesOrderId",
					},
				},
				{
					ItemFulfillments: {
						"ItemFulfillments.id":
							"ItemFulfillmentItems.itemFulfillmentId",
					},
				},
				{
					ItemFulfillmentPackages: {
						"ItemFulfillmentPackages.itemFulfillmentId":
							"ItemFulfillments.id",
					},
				},
				{
					TrackingNumbers: {
						"TrackingNumbers.id":
							"ItemFulfillmentPackages.trackingNumberId",
					},
				},
			],
			where: {
				and: [{ "SalesOrders.uuid": { "=": uuid } }],
			},
		};
		let response = await togaApiRequest(
			"GET",
			"/item-fulfillment-items",
			null,
			options
		);

		if (!response.isSuccess) {
			if (response.status === 403) {
				output.errorCode = 403;
				output.errorMessage =
					response.error ||
					"Operation Failed: You are not authorized to perform this action.";
				return output;
			} else {
				output.errorCode = response.status || 500;
				output.errorMessage =
					response.error ||
					"An error occurred while fetching item fulfillment items.";
				return output;
			}
		}

		// Build an array of item fulfillment UUIDs to assign for each sales order item UUID
		const itemFulfillmentUuids: { [key: string]: any[] } = {};
		for (const itemFulfillmentItem of response.data.itemFulfillmentItems) {
			const salesOrderItemUuid = itemFulfillmentItem.SalesOrderItems.uuid;
			if (!itemFulfillmentUuids[salesOrderItemUuid]) {
				itemFulfillmentUuids[salesOrderItemUuid] = [];
			}

			itemFulfillmentUuids[salesOrderItemUuid].push({
				uuid: itemFulfillmentItem.TrackingNumbers.uuid,
				number: itemFulfillmentItem.TrackingNumbers.number,
			});
		}

		// Bring these into the output.items array
		for (const item of output.items) {
			if (itemFulfillmentUuids[item.uuid]) {
				item.shipments = itemFulfillmentUuids[item.uuid];
			} else {
				item.shipments = [];
			}
		}
	} catch (error: any) {
		console.error("Error fetching item fulfillment items:", error.message);
		output.errorCode = error.response?.status || 500;
		output.errorMessage = error.message || "An unexpected error occurred.";
		return output;
	}

	/* --------------------------------------- Serial Numbers --------------------------------------- */
	try {
		let options: ExtendedOptionsType = {
			fields: ["SalesOrderItems.uuid", "serialNumber"],
			join: [
				{
					ItemFulfillmentItemUnits: {
						"ItemFulfillmentItemUnits.unitId": "Units.id",
					},
				},
				{
					ItemFulfillmentItems: {
						"ItemFulfillmentItems.id":
							"ItemFulfillmentItemUnits.itemFulfillmentItemId",
					},
				},
				{
					SalesOrderItems: {
						"SalesOrderItems.id":
							"ItemFulfillmentItems.salesOrderItemId",
					},
				},
				{
					ItemFulfillments: {
						"ItemFulfillments.id":
							"ItemFulfillmentItems.itemFulfillmentId",
					},
				},
				{
					SalesOrders: {
						"SalesOrders.id": "ItemFulfillments.salesOrderId",
					},
				},
			],
			where: {
				and: [{ "SalesOrders.uuid": { "=": uuid } }],
			},
		};
		let response = await togaApiRequest("GET", "/units", null, options);

		if (!response.isSuccess) {
			if (response.status === 403) {
				output.errorCode = 403;
				output.errorMessage =
					response.error ||
					"Operation Failed: You are not authorized to perform this action.";
				return output;
			} else {
				output.errorCode = response.status || 500;
				output.errorMessage =
					response.error ||
					"An error occurred while fetching serial numbers.";
				return output;
			}
		}

		// Build an array of units by sales order item UUIDs
		const serialNumbersBySalesOrderItemUuid: { [key: string]: string[] } =
			{};
		for (const unit of response.data.units) {
			const salesOrderItemUuid = unit.SalesOrderItems.uuid;
			if (!serialNumbersBySalesOrderItemUuid[salesOrderItemUuid]) {
				serialNumbersBySalesOrderItemUuid[salesOrderItemUuid] = [];
			}

			serialNumbersBySalesOrderItemUuid[salesOrderItemUuid].push(
				unit.serialNumber
			);
		}

		// Bring these into the output.items array
		for (const item of output.items) {
			if (serialNumbersBySalesOrderItemUuid[item.uuid]) {
				item.serialNumbers =
					serialNumbersBySalesOrderItemUuid[item.uuid];
			} else {
				item.serialNumbers = [];
			}
		}
	} catch (error: any) {
		console.error("Error fetching serial numbers:", error.message);
		output.errorCode = error.response?.status || 500;
		output.errorMessage = error.message || "An unexpected error occurred.";
		return output;
	}

	return formatNumbersInObject(output);
}

// INDIVIDUAL FUNCTION CALLS //
/* -------------------------------------- Sales Order Items ------------------------------------- */
export async function getSalesOrderItems(
	uuid: string
): Promise<OrderDetailsResult | undefined> {
	const storedHostName = localStorage.getItem("hostName");

	let output: { salesOrder: OrderDetails | null; invoices: Invoice[] } = {
		salesOrder: null,
		items: [],
		invoices: [],
		shipments: [],
	};

	const options = {
		fields: [
			"uuid",
			"lineNumber",
			"Bundles.name",
			"Bundles.uuid",
			"BundleItems.uuid",
			"BundleItems.isRequired",
			"BundleItemGroups.name",
			"Items.partNumber",
			"Items.skuNumber",
			"Items.upcCode",
			"Items.description",
			"Manufacturers.name",
			"quantity",
			"_qtyFulfilled",
			"_qtyBilled",
			"_extPrice",
			"price",
			"PriceTypes.name",
		],
		ojoin: [
			{
				Items: {
					"Items.id": "SalesOrderItems.itemId",
				},
			},
			{
				Manufacturers: {
					"Manufacturers.id": "Items.manufacturerId",
				},
			},
			{
				BundleItems: {
					"BundleItems.id": "SalesOrderItems.bundleItemId",
				},
			},
			{
				Bundles: {
					"Bundles.id": "BundleItems.bundleId",
				},
			},
			{
				BundleItemGroups: {
					"BundleItemGroups.id": "BundleItems.bundleItemGroupId",
				},
			},
			{
				PriceTypes: {
					"PriceTypes.id": "SalesOrderItems.priceTypeId",
				},
			},
		],
	};
	let response = await togaApiRequest(
		"GET",
		"/sales-orders/" + uuid + "/sales-order-items",
		null,
		options
	);
	if (!response.isSuccess) {
		console.log(":(");
		// return;
	}
	return (output.items = response.data.salesOrderItems);
}

/* ------------------------------------- Sales Order Details ------------------------------------- */
export async function getSalesOrderDetails(
	uuid: string
): Promise<OrderDetailsResult | undefined> {
	const storedHostName = localStorage.getItem("hostName");

	let output: { salesOrder: OrderDetails | null; invoices: Invoice[] } = {
		salesOrder: null,
		items: [],
		invoices: [],
		shipments: [],
	};

	let options: ExtendedOptionsType = {
		join: [
			{
				SalesOrderNotes: {
					"SalesOrderNotes.salesOrderId": "SalesOrders.id",
				},
			},
		],
	};
	let response = await togaApiRequest(
		"GET",
		"/sales-orders/" + uuid,
		null,
		options
	);

	if (!response.isSuccess) {
		console.log(":(");
	}

	return (output.salesOrder = response.data.salesOrders);
}

/* ------------------------------------------ Invoices ------------------------------------------ */
export async function getInvoices(
	uuid: string
): Promise<OrderDetailsResult | undefined> {
	const storedHostName = localStorage.getItem("hostName");

	let output: { salesOrder: OrderDetails | null; invoices: Invoice[] } = {
		salesOrder: null,
		items: [],
		invoices: [],
		shipments: [],
	};

	const options = {
		fields: [
			"uuid",
			"number",
			"dateInvoice",
			"dateDue",
			"total",
			"amountDue",
			"PaymentTerms.name",
			"status",
		],
		join: [
			{
				SalesOrders: {
					"SalesOrders.id": "Invoices.salesOrderId",
				},
			},
		],
		ojoin: [
			{
				PaymentTerms: {
					"PaymentTerms.id": "Invoices.paymentTermsId",
				},
			},
		],
		where: {
			and: [{ "SalesOrders.uuid": { "=": uuid } }],
		},
	};
	const response = await togaApiRequest("GET", "/invoices", null, options);
	// if (!response.isSuccess) {
	// 	console.log(":(");
	// 	return;
	// }
	return (output.invoices = response.data.invoices);
}

//hard-coded logic to pull prudential orders to export to CSV
export async function getPrudentialOrders() {
	const options: ExtendedOptionsType = {
		recordsPerPage: 1000,
		fields: [
			"PurchaseOrders.number",
			"dateShipped",
			"PurchaseOrderStages.name",
			"PurchaseOrders.c_dtTransmittedInProgressUpdateToPrudential",
			"PurchaseOrders.c_dtTransmittedOrderShippedUpdateToPrudential",
			"PurchaseOrders.c_dtTransmittedOrderClosedUpdateToPrudential",
			"SalesOrders.number",
			"SalesOrders.dateOrder",
			"Addresses.line1",
			"Addresses.line2",
			"Addresses.city",
			"States.code",
			"Addresses.zip",
			"ServiceRequests.c_ritmNumber",
			"ServiceRequests.c_reqNumber",
			"TrackingNumbers.number",
			"TrackingNumbers.status",
			"Units.name",
			"Units.serialNumber",
			"Items.partNumber",
			"Items.description",
			"Contacts.firstName",
			"Contacts.lastName",
			"ContactPhoneNumbers.phoneNumber",
			"ContactEmailAddresses.emailAddress",
			"Contacts.c_employeeXid",
		],
		join: [
			{
				AdvanceShippingNoticeItems: {
					"AdvanceShippingNoticeItems.advanceShippingNoticeId":
						"AdvanceShippingNotices.id",
				},
			},
			{
				PurchaseOrders: {
					"PurchaseOrders.id":
						"AdvanceShippingNotices.purchaseOrderId",
				},
			},
			{
				SalesOrders: {
					"SalesOrders.id": "PurchaseOrders.salesOrderId",
				},
			},
			{
				ServiceRequests: {
					"ServiceRequests.id": "SalesOrders.serviceRequestId",
				},
			},
			{
				AdvanceShippingNoticeUnits: {
					"AdvanceShippingNoticeUnits.advanceShippingNoticeItemId":
						"AdvanceShippingNoticeItems.id",
				},
			},
			{
				TrackingNumbers: {
					"TrackingNumbers.id":
						"AdvanceShippingNoticeUnits.trackingNumberId",
				},
			},
			{ Units: { "Units.id": "AdvanceShippingNoticeUnits.unitId" } },
			{ Items: { "Items.id": "Units.itemId" } },
			{ Contacts: { "Contacts.id": "Units.contactId" } },
			{
				Addresses: {
					"Addresses.id": "AdvanceShippingNotices.shipToAddressId",
				},
			},
			{ States: { "States.id": "Addresses.stateId" } },
			{
				PurchaseOrderStages: {
					"PurchaseOrderStages.id":
						"PurchaseOrders.purchaseOrderStageId",
				},
			},
			{
				ContactPhoneNumbers: {
					"ContactPhoneNumbers.id":
						"Contacts.primaryContactPhoneNumberId",
				},
			},
			{
				ContactEmailAddresses: {
					"ContactEmailAddresses.id":
						"Contacts.primaryContactEmailAddressId",
				},
			},
		],
	};

	let allData = [];
	let page = 1;
	let totalRecordCount = 0;

	do {
		options.page = page;
		const response = await togaApiRequest(
			"GET",
			"/advance-shipping-notices",
			null,
			options
		);

		if (response.isSuccess) {
			allData = [...allData, ...response.data.advanceShippingNotices];
			totalRecordCount = response.meta.totalRecordCount;
			page++;
		} else {
			console.log("Error fetching data");
			break;
		}
	} while (allData.length < totalRecordCount);

	return allData;
}

//invoice PDf details
export async function getInvoicePdf(uuid: string) {
	const options = {
		fields: ["number", "invoicePdfFile"],
		where: {
			and: [{ "Invoices.uuid": { "=": uuid } }],
		},
	};
	const invoicePdfResponse = await togaApiRequest(
		"GET",
		"/invoices",
		null,
		options
	);
	if (invoicePdfResponse.isSuccess) {
		return invoicePdfResponse.data.invoices[0];
	}
	// else {
	// 	console.log(":("); //Pass the error to handle appropriately on the screen
	// }
}
// Getting tracking number using Purchase Order UUID for Prudential
export async function getTrackingNumber(uuid: string) {
	const options: ExtendedOptionsType = {
		fields: ["uuid", "number"],
		join: [
			{
				AdvanceShippingNoticeUnits: {
					"AdvanceShippingNoticeUnits.trackingNumberId":
						"TrackingNumbers.id",
				},
			},
			{
				AdvanceShippingNoticeItems: {
					"AdvanceShippingNoticeItems.id":
						"AdvanceShippingNoticeUnits.advanceShippingNoticeItemId",
				},
			},
			{
				AdvanceShippingNotices: {
					"AdvanceShippingNotices.id":
						"AdvanceShippingNoticeItems.advanceShippingNoticeId",
				},
			},
			{
				PurchaseOrders: {
					"PurchaseOrders.id":
						"AdvanceShippingNotices.purchaseOrderId",
				},
			},
			{
				SalesOrders: {
					"SalesOrders.id": "PurchaseOrders.salesOrderId",
				},
			},
		],
		where: {
			and: [{ "SalesOrders.uuid": { "=": uuid } }],
		},
	};

	const response = await togaApiRequest(
		"GET",
		"/tracking-numbers",
		null,
		options
	);

	if (response.isSuccess) {
		return response.data.trackingNumbers;
	} else {
		console.log(":("); //Pass the error to handle appropriately on the screen
	}
}

// sales order's side Bar View
export async function getSalesOrderSideBar(uuid: string) {
	const storedHostName = localStorage.getItem("hostName");

	let output: { salesOrder: OrderDetails | null; invoices: Invoice[] } = {
		salesOrder: null,
		invoices: [],
	};

	let options: ExtendedOptionsType = {};
	if (storedHostName == "PRUDENTIAL") {
		options = {
			fields: [
				"uuid",
				"number",
				"_status",
				"dateOrder",
				"description",
				"contact.uuid",
				"contact.firstName",
				"contact.lastName",
				"contact.title",
				"contact.c_employeeXid",
				"contact.primaryContactPhoneNumber.uuid",
				"contact.primaryContactPhoneNumber.name",
				"contact.primaryContactPhoneNumber.phoneNumber",
				"contact.primaryContactEmailAddress.uuid",
				"contact.primaryContactEmailAddress.name",
				"contact.primaryContactEmailAddress.emailAddress",
				"shipToAddress.uuid",
				"shipToAddress.addressee",
				"shipToAddress.attention",
				"shipToAddress.line1",
				"shipToAddress.line2",
				"shipToAddress.city",
				"shipToAddress.zip",
				"shipToAddress.state.uuid",
				"shipToAddress.state.code",
				"shipToAddress.state.name",
				"shippingMethod.uuid",
				"shippingMethod.shippingCarrierId",
				"shippingMethod.name",
				"serviceRequest.c_reqNumber",
				"serviceRequest.c_ritmNumber",
				"_updated",
			],

			where: {
				and: [{ "SalesOrders.uuid": { "=": uuid } }],
			},
		};
	} else {
		options = {
			fields: [
				"uuid",
				"number",
				"dateOrder",
				"_status",
				"contact.uuid",
				"contact.firstName",
				"contact.lastName",
				"contact.title",
				"contact.primaryContactPhoneNumber.uuid",
				"contact.primaryContactPhoneNumber.name",
				"contact.primaryContactPhoneNumber.phoneNumber",
				"contact.primaryContactEmailAddress.uuid",
				"contact.primaryContactEmailAddress.name",
				"contact.primaryContactEmailAddress.emailAddress",
				"shipToAddress.uuid",
				"shipToAddress.addressee",
				"shipToAddress.attention",
				"shipToAddress.line1",
				"shipToAddress.line2",
				"shipToAddress.city",
				"shipToAddress.zip",
				"shipToAddress.phoneNumber",
				"shipToAddress.state.uuid",
				"shipToAddress.state.code",
				"shipToAddress.state.name",
				"shippingMethod.uuid",
				"shippingMethod.shippingCarrierId",
				"shippingMethod.name",
				"_updated",
			],

			where: {
				and: [{ "SalesOrders.uuid": { "=": uuid } }],
			},
		};
	}

	const salesOderDetailsResponse = await togaApiRequest(
		"GET",
		"/sales-orders",
		null,
		options
	);

	if (!salesOderDetailsResponse.isSuccess) {
		console.log("Error fetching sales order details");
		return;
	}

	output.salesOrder = salesOderDetailsResponse.data.salesOrders[0];

	// invoices
	const optionsInvoice: ExtendedOptionsType = {
		fields: ["uuid", "number", "dateInvoice", "total"],
		join: [{ SalesOrders: { "SalesOrders.id": "Invoices.salesOrderId" } }],
		where: {
			and: [{ "SalesOrders.uuid": { "=": uuid } }],
		},
	};
	//Getting Invoices of Sales order
	const invoicesResponse = await togaApiRequest(
		"GET",
		"/invoices",
		null,
		optionsInvoice
	);

	if (!invoicesResponse.isSuccess) {
		console.log(":(");
		return;
	}

	output.invoices = invoicesResponse.data.invoices;

	// tracking numbers
	const optionsTracking: ExtendedOptionsType = {
		join: [
			{
				ItemFulfillmentPackages: {
					"TrackingNumbers.id":
						"ItemFulfillmentPackages.trackingNumberId",
				},
			},
			{
				ItemFulfillments: {
					"ItemFulfillments.id":
						"ItemFulfillmentPackages.itemFulfillmentId",
				},
			},
			{
				SalesOrders: {
					"SalesOrders.id": "ItemFulfillments.salesOrderId",
				},
			},
		],
		where: {
			and: [{ "SalesOrders.uuid": { "=": uuid } }],
		},
		fields: [
			"number",
			{
				shippingCarrier: ["name", "urlLogo", "urlLinkPrefix"],
				shippingMethod: ["name"],
			},
		],
	};
	if (storedHostName == "PRUDENTIAL") {
		const trackingResponse = await getTrackingNumber(uuid);

		if (
			output.salesOrder &&
			trackingResponse &&
			trackingResponse.length > 0
		) {
			output.salesOrder.trackingNumbers = [
				{
					shippingCarrierId: "1",
					shippingMethodId: null,
					number: trackingResponse[0].number,
					shippingCarrier: {
						name: "FedEx",
						urlLogo: "/carrierlogos/FedEx-logo.svg",
						urlLinkPrefix:
							"https://www.fedex.com/fedextrack/?trknbr=",
					},
					shippingMethod: {
						name: null,
					},
				},
			];
		}
	} else {
		//Getting tracking numbers of sales order
		const trackingResponse = await togaApiRequest(
			"GET",
			"/tracking-numbers",
			null,
			optionsTracking
		);

		if (!trackingResponse.isSuccess) {
			console.log(":(");
			return;
		}

		//Get Invoice Tracking Numbers
		const optionsInvoiceTracking: ExtendedOptionsType = {
			join: [
				{
					InvoiceTrackingNumbers: {
						"InvoiceTrackingNumbers.trackingNumberId":
							"TrackingNumbers.id",
					},
				},
				{
					Invoices: {
						"Invoices.id": "InvoiceTrackingNumbers.invoiceId",
					},
				},
				{
					SalesOrders: {
						"SalesOrders.id": "Invoices.salesOrderId",
					},
				},
			],
			where: {
				and: [{ "SalesOrders.uuid": { "=": uuid } }],
			},
			fields: [
				"number",
				{
					shippingCarrier: ["name", "urlLogo", "urlLinkPrefix"],
					shippingMethod: ["name"],
				},
			],
		};

		//Getting tracking numbers of sales order
		const invoiceTrackingResponse = await togaApiRequest(
			"GET",
			"/tracking-numbers",
			null,
			optionsInvoiceTracking
		);

		if (!invoiceTrackingResponse.isSuccess) {
			console.log(":(");
			return;
		}

		const combinedTrackingNumbers = [
			...trackingResponse.data.trackingNumbers,
			...invoiceTrackingResponse.data.trackingNumbers,
		];
		// Filter for unique tracking numbers
		const uniqueTrackingNumbers = combinedTrackingNumbers.reduce(
			(acc, current) => {
				if (!acc.find((item) => item.number === current.number)) {
					acc.push(current);
				}
				return acc;
			},
			[]
		);

		if (output.salesOrder) {
			output.salesOrder.trackingNumbers = combinedTrackingNumbers;
		}
	}

	return formatNumbersInObject(output);
}

// when you're in the skip manager modal as an admin:
// - do not want to check for the manager pay grade

// when you're a manager and you're approving/denying an order
// - do not want to check for the manager pay grade

// we DO want to check manager paygrade:
// when an admin is approving an order, which is in pending initial approval, only then we check the manager paygrade

export async function denySalesOrder(
	salesOrderUuid: string,
	status: string,
	userUuid: number,
	reason: string,
	isAdmin: boolean
): Promise<{ status: number; data: any }> {
	const data = {
		salesOrderStage: {
			name: status,
		},
	} as {
		salesOrderStage: any;
		approvalUser?: any;
		c_managerApprovalSkipped?: number;
		c_dtManagerApproved?: string;
		c_adminApproval?: string;
		c_dtAdminApproved?: string;
	};

	const options = {
		fields: ["c_dtAdminApproved"],
		where: {
			and: [{ "SalesOrders.uuid": { "=": salesOrderUuid } }],
		},
	};
	const fetchResponse = await togaApiRequest(
		"GET",
		"/sales-orders",
		null,
		options
	);
	const dtAdminApproved = fetchResponse.data.SalesOrders?.c_dtAdminApproved;
	if (dtAdminApproved == null || dtAdminApproved == undefined) {
		data["c_adminApproval"] = {
			uuid: userUuid,
		};
		data["c_dtAdminApproved"] = new Date();
	} else {
		data["c_dtManagerApproved"] = new Date();
	}

	if (reason) {
		await updateSalesOrderNotes(salesOrderUuid, userUuid, reason, "denial");
	}

	const response = await togaApiRequest(
		"PUT",
		"/sales-orders/" + salesOrderUuid,
		data,
		{ depth: 1 }
	);
	const output = response.data;

	return { status: response.status, data: output };
}

// FIXME: logic should be moved to the backend

export async function updateSalesOrder(
	salesOrderUuid: string,
	status: string,
	managerId: number,
	skipManagerApproval: boolean,
	memo: string,
	isAdmin: boolean
): Promise<{ status: number; data: any }> {
	/////// CONSTANT - USED FOR ALL MODAL TYPES AND STAGES ///////
	const data = {
		salesOrderStage: {
			name: status,
		},
	} as {
		salesOrderStage: any;
		approvalUser?: any;
		c_managerApprovalSkipped?: number;
		c_dtManagerApproved?: string;
		c_adminApproval?: string;
		c_dtAdminApproved?: string;
	};

	/////// Get Manager's Pay grade & order total - for when an admin is approving an order in pending initial approval	///////

	const optionsForSalesOrders: ExtendedOptionsType = {
		join: [
			{
				Users: {
					"Users.id": "SalesOrders.approvalUserId",
				},
			},
		],
		fields: ["_total", "approvalUser.uuid"],
	};
	const responseOrder = await togaApiRequest(
		"GET",
		"/sales-orders/" + salesOrderUuid,
		null,
		optionsForSalesOrders
	);
	let output = responseOrder.data;
	const total = output.salesOrders._total;

	const managerUuid = output.salesOrders?.approvalUser?.uuid;
	const optionsForUser: ExtendedOptionsType = {
		fields: ["c_payGroupNbr"],
		where: {
			and: [{ "Users.uuid": { "=": managerUuid } }],
		},
	};
	const responseUser = await togaApiRequest(
		"GET",
		"/users",
		null,
		optionsForUser
	);
	const outputUser = responseUser.data;
	const payGroupNbr = outputUser.users[0]?.c_payGroupNbr;

	//If payscale = 18 and total < 5000, then directly move the order to Pending Approval
	if (payGroupNbr >= 18 && total < 5000) {
		status = "Pending Fulfillment";
		skipManagerApproval = true;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	data["salesOrderStage"] = {
		name: status,
	};

	if (skipManagerApproval) {
		data["c_managerApprovalSkipped"] = 1;
		data["c_dtManagerApproved"] = new Date();
		data["c_adminApproval"] = {
			uuid: managerId,
		};
		data["c_dtAdminApproved"] = new Date();
	} else if (isAdmin) {
		data["c_adminApproval"] = {
			uuid: managerId,
		};
		data["c_dtAdminApproved"] = new Date();
	}
	if ((memo != null || memo != undefined) && status !== "Canceled") {
		await updateSalesOrderNotes(
			salesOrderUuid,
			managerId,
			memo,
			"approval"
		);
	}
	if (status === "Pending Fulfillment" && !skipManagerApproval) {
		data["c_dtManagerApproved"] = new Date();
	}

	const response = await togaApiRequest(
		"PUT",
		"/sales-orders/" + salesOrderUuid,
		data,
		{ depth: 1 }
	);
	output = response.data;
	return { status: response.status, data: output };
}

export async function updateUserAssignment(
	approverField?: "approvalUser" | "c_adminApproval",
	approverUuid?: string,
	salesOrderUuid: string
): Promise<{ status: number; data: any }> {
	const data = {} as {
		approvalUser?: any;
		c_adminApproval?: any;
	};

	if (approverField && approverUuid) {
		data[approverField] = {
			uuid: approverUuid,
		};
	}
	const response = await togaApiRequest(
		"PUT",
		"/sales-orders/" + salesOrderUuid,
		data,
		{}
	);

	const output = response.data;

	return { status: response.status, data: output };
}

export async function updateSalesOrderNotes(
	salesOrderUuid: string,
	contactUuid: number,
	memo: string,
	salesOrderNoteType: string
) {
	var userOptions = {
		join: [
			{
				Contacts: {
					"Contacts.id": "Users.contactId",
				},
			},
		],
		fields: ["contactId", "Contacts.uuid"],
		where: {
			and: [{ "Users.uuid": { "=": contactUuid } }],
		},
	};
	var fetchResponse = await togaApiRequest(
		"GET",
		"/users",
		null,
		userOptions
	);

	let contactId = fetchResponse.data?.users[0]?.contactId;
	let contactUuidParam = fetchResponse.data?.users[0]?.Contacts.uuid;

	const data = {
		salesOrder: { uuid: salesOrderUuid },
		salesOrderNoteType: { slug: salesOrderNoteType },
		contact: { uuid: contactUuidParam },
		dtStamp: new Date(),
		note: memo,
	};
	const response = await togaApiRequest("POST", "/sales-order-notes/", data, {
		depth: 1,
	});
	var output = response.data;
}

// Getting Sales order details View

function formatNumbersInObject(obj) {
	if (Array.isArray(obj)) {
		return obj.map(formatNumbersInObject);
	} else if (typeof obj === "object" && obj !== null) {
		const formattedObject = {};
		Object.entries(obj).forEach(([key, value]) => {
			formattedObject[key] = formatNumbersInObject(value);
		});
		return formattedObject;
	} else if (typeof obj === "number") {
		// Keep as a string to preserve two decimal places
		return obj.toFixed(2);
	}
	return obj;
}

export async function getSearchValuesFromAdvancedInput(
	limit: number,
	page: number,
	value: string
) {
	const userOptions = {
		recordsPerPage: limit,
		page: page,
		fields: ["uuid", "_name", "email"],
		sort: ["_name"],
		join: [
			{
				Users_Roles: {
					"Users.id": "Users_Roles.userId",
				},
			},
			{
				Roles: {
					"Roles.id": "Users_Roles.roleId",
				},
			},
		],
		where: {
			and: [{ _name: { starts: value } }],
		},
	};

	let allUsers: any[] = [];
	// let page = 1; // Starting from page 1
	let totalRecordCount = 0; // Initialize total count
	let hasNextPage = true;

	try {
		userOptions.page = page;

		const userResponse = await togaApiRequest(
			"GET",
			"/users",
			null,
			userOptions
		);

		if (userResponse.isSuccess) {
			const { data, meta } = userResponse;

			// Append users to the overall list
			allUsers = [...allUsers, ...data.users];

			// Update totalRecordCount and log for debugging
			totalRecordCount = meta?.totalRecordCount || 0;

			// Check if there is a next page or if we've fetched enough users
			hasNextPage = !!meta?.nextPage && allUsers.length < limit;

			// Increment page for the next iteration
			page++;
		} else {
			console.error("Error fetching user data", userResponse);
			hasNextPage = false; // Exit loop on failure
			// }
		}

		return allUsers;
	} catch (error) {
		console.error("Error fetching data:", error);
		return [];
	}
}
// { "Users.firstName": { like: requestedUserNames } },

export async function getAdminsAndManagers(limit: number, page: number) {
	const userOptions = {
		recordsPerPage: limit,
		page: page,
		fields: ["uuid", "_name", "email"],
		sort: ["_name"],
		join: [
			{
				Users_Roles: {
					"Users.id": "Users_Roles.userId",
				},
			},
			{
				Roles: {
					"Roles.id": "Users_Roles.roleId",
				},
			},
		],
		where: {
			and: [
				{ "Users.isActive": { "=": "1" } },
				{
					or: [
						{ "Roles.name": { "=": "Admin" } },
						{ "Roles.name": { "=": "Manager" } },
					],
				},
			],
		},
	};

	let allUsers: any[] = [];
	// let page = 1; // Starting from page 1
	let totalRecordCount = 0; // Initialize total count
	let hasNextPage = true;

	try {
		// while (hasNextPage && allUsers.length < limit) {
		// Update the user options with the current page
		userOptions.page = page;

		const userResponse = await togaApiRequest(
			"GET",
			"/users",
			null,
			userOptions
		);

		if (userResponse.isSuccess) {
			const { data, meta } = userResponse;

			// Append users to the overall list
			allUsers = [...allUsers, ...data.users];

			// Update totalRecordCount and log for debugging
			totalRecordCount = meta?.totalRecordCount || 0;

			// Check if there is a next page or if we've fetched enough users
			hasNextPage = !!meta?.nextPage && allUsers.length < limit;

			// Increment page for the next iteration
			page++;
		} else {
			console.error("Error fetching user data", userResponse);
			hasNextPage = false; // Exit loop on failure
			// }
		}

		return allUsers.slice(0, limit); // Return only the first `limit` users
	} catch (error) {
		console.error("Error fetching data:", error);
		return [];
	}
}

export async function getAdmins() {
	const userOptions = {
		fields: ["uuid", "_name", "email"],
		recordsPerPage: 1000,
		join: [
			{
				Users_Roles: {
					"Users.id": "Users_Roles.userId",
				},
			},
			{
				Roles: {
					"Roles.id": "Users_Roles.roleId",
				},
			},
		],

		where: {
			and: [
				{ "Roles.name": { "=": "Admin" } },
				{ clientId: { "=": null } },
			],
		},
	};

	const userResponse = await togaApiRequest(
		"GET",
		"/users",
		null,
		userOptions
	);

	return userResponse.data.users;
}
