import { useState, useEffect } from "react";
import { useUrl } from ".";

interface UseSortArrowsViewModelProps {
	columnId: string;
	slug: string;
	isNested?: boolean;
	parentIndex?: number;
	onSortChange?: (column: string, direction: "asc" | "desc" | "") => void;
	setSortColumn: React.Dispatch<
		React.SetStateAction<{ [key: string]: string | string[] }>
	>;
}

export const useSortArrowsViewModel = ({
	columnId,
	slug,
	parentIndex,
	onSortChange,
	setSortColumn,
}: UseSortArrowsViewModelProps) => {
	const { setParentIndex } = useUrl();

	const [activeSortColumns, setActiveSortColumns] = useState<
		{ columnId: string; direction: "asc" | "desc" }[]
	>([]);

	const [showArrowContainer, setShowArrowContainer] =
		useState<boolean>(false);

	useEffect(() => {
		const storedSortInfo = localStorage.getItem("activeSortColumns");
		if (storedSortInfo) {
			setActiveSortColumns(JSON.parse(storedSortInfo));
		} else {
			const query = new URLSearchParams(window.location.search);
			const sortParams = query.getAll(`${slug}`);
			if (sortParams.length) {
				const parsedSortColumns = sortParams.map((sortValue) => {
					let direction: "asc" | "desc" = "asc";
					let columnId = sortValue;

					if (sortValue.startsWith("-")) {
						direction = "desc";
						columnId = sortValue.substring(1);
					} else if (sortValue.startsWith("+")) {
						direction = "asc";
						columnId = sortValue.substring(1);
					} else {
						// If no "+" or "-", default to ascending
						direction = "asc";
					}

					return {
						columnId,
						direction,
					};
				});
				setActiveSortColumns(parsedSortColumns.slice(0, 3));
			}
		}
	}, [slug]);

	const updateSortState = (direction: "up" | "down") => {
		const newDirection: "asc" | "desc" =
			direction === "up" ? "asc" : "desc";

		// Find the existing column in activeSortColumns
		let updatedActiveSortColumns = activeSortColumns.map((col) =>
			col.columnId === columnId
				? { ...col, direction: newDirection }
				: col
		);

		// If the columnId was not found, add it to the beginning
		const columnExists = updatedActiveSortColumns.some(
			(col) => col.columnId === columnId
		);

		if (!columnExists) {
			updatedActiveSortColumns.unshift({
				columnId,
				direction: newDirection,
			});
		}

		// Limit to 3 active sort columns
		updatedActiveSortColumns = updatedActiveSortColumns.slice(0, 3);

		// Update local state
		setActiveSortColumns(updatedActiveSortColumns);
		localStorage.setItem(
			"activeSortColumns",
			JSON.stringify(updatedActiveSortColumns)
		);

		// Update the URL parameters
		const sortParams = updatedActiveSortColumns.map((col) => {
			return (col.direction === "desc" ? "-" : "+") + col.columnId;
		});

		// Merge the new sort with existing `sortColumn` state
		setSortColumn((prev) => {
			// Clone the previous state
			const updatedSortColumn = { ...prev };

			// Add/Replace the current slug with the updated sort parameters
			updatedSortColumn[slug] = sortParams;

			return updatedSortColumn;
		});

		// Trigger callback if provided
		onSortChange?.(columnId, newDirection);
	};

	const handleParentClick = () => {
		setShowArrowContainer(!showArrowContainer);
	};

	const handleResetSort = () => {
		const updatedActiveSortColumns = activeSortColumns.filter(
			(col) => col.columnId !== columnId
		);

		// Update local state and localStorage
		setActiveSortColumns(updatedActiveSortColumns);
		localStorage.setItem(
			"activeSortColumns",
			JSON.stringify(updatedActiveSortColumns)
		);

		// Update the URL parameters
		const sortParams = updatedActiveSortColumns.map((col) => {
			return (col.direction === "desc" ? "-" : "+") + col.columnId;
		});

		// Update the state for sortColumn
		setSortColumn((prev) => {
			const updatedSortColumn = { ...prev };
			updatedSortColumn[slug] = sortParams;

			// Remove the slug if no sortParams remain
			if (sortParams.length === 0) {
				delete updatedSortColumn[slug];
			}

			return updatedSortColumn;
		});

		// Sync the URL using `useUrl`
		const queryParams = new URLSearchParams(window.location.search);
		[...queryParams.keys()].forEach((key) => {
			if (key.includes(`${slug}_sort`)) {
				queryParams.delete(key); // Remove sort keys from the URL
			}
		});

		// Add remaining sortParams back to the URL (if any)
		updatedActiveSortColumns.forEach((col, index) => {
			queryParams.append(
				`${slug}_sort[${index}]`,
				(col.direction === "desc" ? "-" : "+") + col.columnId
			);
		});

		// Update the URL without reloading the page
		window.history.replaceState(
			null,
			"",
			`${window.location.pathname}?${queryParams.toString()}`
		);

		setParentIndex?.(0);

		// Trigger callback if provided
		onSortChange?.(columnId, ""); // Reset the sort direction to ""
	};

	const handleClick = (direction: "up" | "down") => {
		updateSortState(direction);
		setParentIndex?.(parentIndex || 0);
	};

	const columnIndex = activeSortColumns.findIndex(
		(col) => col.columnId === columnId
	);
	const isActive = columnIndex !== -1;
	const activeDirection = isActive
		? activeSortColumns[columnIndex].direction
		: null;
	const sortOrderNumber = isActive ? columnIndex + 1 : null;

	return {
		activeSortColumns,
		activeDirection,
		showArrowContainer,
		handleParentClick,
		handleResetSort,
		handleClick,
		isActive,
		sortOrderNumber,
		setShowArrowContainer,
	};
};

export default useSortArrowsViewModel;
