import React, { useRef, useState } from "react";
import LoadingMessage from "../../../../../components/misc/LoadingMessage";
import { motion, AnimatePresence } from "framer-motion";
import OrderView from "./OrderViewModalContent";
import {
	slideUpModalOverlayVariants,
	slideUpModalVariants,
	tableVariants,
} from "../../../../../motionVariants";
import { clearLocalStorage } from "../../../../../utils";

interface OrderViewSliderModalProps<T> {
	isOpen: boolean;
	onClose: () => void;
	closeButton?: (handleClose: () => void) => React.ReactNode;
	hasBorder?: boolean;
	borderColor?: string;
	loadingMessage?: string;
	navChildren?: React.ReactNode;
	cancelButton?: React.ReactNode;
	saveButton?: React.ReactNode;
	parentData?: any;
}

const OrderViewSliderModal = <T,>({
	isOpen,
	onClose,
	hasBorder = false,
	borderColor = "primary",
	loadingMessage = "",
	parentData,
}: OrderViewSliderModalProps<T>) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const handleClose = () => {
		setIsMenuOpen(false);
		onClose();
		clearLocalStorage(["uuid", "orders"]);
		document.body.classList.remove("overflow-hidden");
	};
	const handleModalOpen = () => {
		document.body.classList.add("overflow-hidden");
	};

	const modalContainerClassNames =
		"fixed inset-0 flex items-end justify-center z-50 max-w-[1690px]  mx-auto modal-bottom";
	const modalBorderClassNames = `modal-box min-w-wideModal border-0  h-[95dvh] p-0 ${
		isMenuOpen ? " overflow-hidden pt-0" : ` pt-0 `
	}  ${hasBorder ? `border-${borderColor}` : "border-transparent"}`;
	const modalOverLayClassNames =
		"fixed inset-0 bg-gray-300 z-40 hover:cursor-pointer";

	return (
		<>
			<AnimatePresence>
				{isOpen && (
					<>
						{handleModalOpen()}
						<motion.div
							className={modalContainerClassNames}
							onClick={handleClose}
							initial={slideUpModalVariants.initial()}
							animate={slideUpModalVariants.animate()}
							exit={slideUpModalVariants.exit()}
							transition={slideUpModalVariants.transition}
						>
							<div className="min-w-full">
								{isLoading ? (
									<motion.div
										{...tableVariants}
										className={`${modalBorderClassNames} min-full min-h-[95vh] flex justify-center items-center`}
									>
										<div className="h-full">
											<LoadingMessage
												message={loadingMessage}
											/>
										</div>
									</motion.div>
								) : (
									<div className={modalBorderClassNames}>
										<motion.div>
											<OrderView
												modalRef={modalRef}
												handleClose={handleClose}
												// parentData={parentData}
											/>
										</motion.div>
									</div>
								)}
							</div>
						</motion.div>
						<>
							<motion.div
								{...slideUpModalOverlayVariants}
								className={modalOverLayClassNames}
								onClick={handleClose}
							></motion.div>
						</>
					</>
				)}
			</AnimatePresence>
		</>
	);
};

export default OrderViewSliderModal;
